import actions from "./actions"
import mutations from "./mutations"


const state = {
  logs: []
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
