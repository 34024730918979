const SET_PRICE_LISTS = (state, priceLists) => {
  state.priceLists = priceLists
}

const SET_LOGS = (state, logs) => {
  state.logs = logs
}

const SET_PRICE_LIST_CURRENT = (state, priceList) => {
  state.priceListCurrent = priceList;
}

const SET_ERP_PRICE_LISTS = (state, priceLists) => {
  state.erpPriceLists = priceLists;
}

export default {
  SET_PRICE_LISTS,
  SET_LOGS,
  SET_PRICE_LIST_CURRENT,
  SET_ERP_PRICE_LISTS
}
