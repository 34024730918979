import Vue from "vue";

const getObservationDocuments = async ({rootState, commit}, params) => {
    let query = "";
    if(params.type && params.name)
        query = `&${params.type}=${params.name}`;

    return await Vue.prototype.$bahngleis.get(`ObservationDocument?ecommerceName=${rootState.ecommerce.Name}&currentPage=${params.currentPage}&perPage=${params.perPage}${query}`)
    .then(response => {
        commit('SET_OBSERVATION_DOCUMENTS',  response.data);
    });
}

const getLogObservationDocument = async ({ rootState, commit }, id) => {
    return await Vue.prototype.$bahngleis.get(`LogAction/getlogsofentity?identity=${id}&entity=ObservationDocument`)
        .then(response => {
            commit('SET_LOGS', response.data);
        });
}

const createObservationDocument = async ({rootState, commit}, payload) => {
    payload.EcommerceName = rootState.ecommerce.Name;
    return await Vue.prototype.$bahngleis.post("ObservationDocument", payload);
}

const updateObservationDocument = async ({commit}, payload) => {
 
    return await Vue.prototype.$bahngleis.put(`ObservationDocument/${payload.Id}`, payload);

}

const removeObservationDocument = async ({}, id) => {
    return await Vue.prototype.$bahngleis.delete(`ObservationDocument/${id}`);
}


export default {
    getObservationDocuments,
    getLogObservationDocument,
    createObservationDocument,
    updateObservationDocument,
    removeObservationDocument 
}