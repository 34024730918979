<template>
  <base-nav v-if="this.$store.state.ecommerce" v-model="showMenu"
    class="navbar-absolute top-navbar col-12 d-flex justify-content-center justify-content-between flex-wrap"
    type="white" :transparent="true">
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline"><sidebar-toggle-button /></div>
      <div class="navbar-toggle d-inline" :class="{ toggled: $sidebar.showSidebar }">
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <router-link to="/">
        <svg class="bahn-logo" width="150" height="48" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 468.75 149.96">
          <defs>
            <linearGradient id="linear-gradient" x1="21.99" y1="128.01" x2="128.06" y2="21.95"
              gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="#00ad9f" />
              <stop offset="1" stop-color="#00fff5" />
            </linearGradient>
          </defs>
          <!-- <title>Bahn_5</title> -->
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <ellipse class="cls-1" cx="75.02" cy="74.98" rx="75.02" ry="74.98" />
              <path class="cls-2"
                d="M86.77,47.1a2.61,2.61,0,0,1,3.38-3.63l17.24,26.85c2,2,2,6.56.5,8.57L90.15,105.74a2.61,2.61,0,0,1-3.38-3.63L99.49,78.79a8.7,8.7,0,0,0,0-8.37Z" />
              <path class="cls-2"
                d="M46.44,47.1a2.59,2.59,0,0,1-.32-1.26,2.63,2.63,0,0,1,.7-1.78,2.6,2.6,0,0,1,3-.59L67.06,70.32c2,2,2,6.56.51,8.57L49.82,105.74a2.61,2.61,0,0,1-3.38-3.63L59.17,78.79a8.76,8.76,0,0,0,0-8.37Z" />
              <path class="cls-2"
                d="M66.6,47.1a2.71,2.71,0,0,1-.32-1.26A2.62,2.62,0,0,1,70,43.47L87.23,70.32c2,2,2,6.56.5,8.57L70,105.74a2.62,2.62,0,0,1-3.39-3.63L79.33,78.79a8.76,8.76,0,0,0,0-8.37Z" />
              <path class="cls-2 name"
                d="M240.11,94c0,15.93-11.6,26.6-26.49,26.6H180.54V28.35h26.88c16.08,0,25.57,8.3,25.57,21.87,0,11.19-5.27,17.12-9.75,20C234.31,74.58,240.11,83,240.11,94ZM193.45,40.21v24.5h15.29c6.46,0,11.34-6.59,11.34-12.65,0-6.72-3.56-11.85-12.66-11.85ZM227.19,92c0-7.51-5.14-14.49-14.76-14.49h-19V108.7h20C221.39,108.7,227.19,101.32,227.19,92Z" />
              <path class="cls-2 name"
                d="M253.28,87.62a33.94,33.94,0,1,1,67.87,0v32.93H308.24V110.14c-3.17,6.46-11.6,11.46-21,11.46A34,34,0,0,1,253.28,87.62Zm55.09,0c0-12-8.83-21.47-20.95-21.47S266.2,75.64,266.2,87.62a21.09,21.09,0,0,0,21.22,21.47C299.41,109.09,308.37,99.61,308.37,87.62Z" />
              <path class="cls-2 name"
                d="M395,85.91v34.64H382V85.91c0-13.43-5.79-19.76-16.07-19.76s-16.08,6.33-16.08,19.5v34.9H337V28.35h12.92V60.62c4.22-5.27,9.62-7,16.08-7C382.83,53.64,395,63.78,395,85.91Z" />
              <path class="cls-2 name"
                d="M468.75,120.55H455.83V85.91c0-13.43-5.8-19.76-16.08-19.76s-16.07,6.33-16.07,19.76v34.64H410.76V85.91c0-22.13,12.13-32.27,29-32.27s29,10.14,29,32.27Z" />
            </g>
          </g>
        </svg>
      </router-link>
    </div>

    <ul class="navbar-nav">
      <el-select class="select-primary" placeholder="Single Select" id="testeId" v-model="ecommerceCurrent"
        v-if="this.$store.state.ecommerceList" v-on:change="changeEcommerce()">
        <template slot="prefix">
          <img class="prefix icon-ecommerce" :src="getPathImage()" />
        </template>
        <el-option v-for="ecommerce in this.$store.state.ecommerceList" :value="ecommerce.Id" :label="ecommerce.Name"
          :key="ecommerce.Name">
          <img style="float: left;" class="icon-sub-ecommerce" :src="getPathImage(ecommerce.Id)" />
          <span style="float: right">{{ ecommerce.Name }}</span>
        </el-option>
      </el-select>
    </ul>
    <ul class="navbar-nav ml-auto app-status-card">
      <div tag="li" title-tag="a" class="nav-item">
        <div class="app-status-title">
          <i class="tim-icons icon-sound-wave" style="padding: 5px"></i>
          <label>
            Status do sistema:
            <label v-if="this.status == 'Normal'" class="app-status-title-running">{{
              status
            }}</label>

            <label v-else-if="this.status == 'Em manutenção'" class="app-status-title-warning">{{ status
              }}</label>

            <label v-else class="app-status-title-stopped">{{ status }}</label>
          </label>
        </div>
      </div>
    </ul>
    <ul class="navbar-nav ml-auto">
      <div class="d-flex justify-content-between">
        <el-tooltip content="Nos Avalie" effect="light" :open-delay="100">
          <a href="https://forms.gle/tRH2XVPEmVVz8NVz5" class="pr-3" target="_blank">
            <i style="font-size: 20px; color: #FFD700;" class="el-icon-star-on"></i>
          </a>
        </el-tooltip>
        <el-tooltip content="Central de Ajuda" effect="light" :open-delay="100">
          <a href="https://ajuda.nordware.io/hc/pt-br/categories/7492299968151-Bahn" class="pr-3" target="_blank">
            <i style="font-size: 20px" class="el-icon-question"></i>
          </a>
        </el-tooltip>
        <el-tooltip content="Visualizar informações restritas" effect="light" :open-delay="100">
          <i v-if="getUserPermission('45_r')" @click="toggleRestrictedDataDisplay()"
            style="font-size: 20px; color: #7DD7DC; cursor: pointer;"
            :class="canEditPrivateInformation ? 'pr-3 fa fa-eye' : 'pr-3 fa fa-eye-slash'"></i>
          <i v-else style="font-size: 20px; cursor: not-allowed;" class="pr-3 fa fa-eye-slash"></i>
        </el-tooltip>
        <el-tooltip content="Assistente de Configuração" effect="light" :open-delay="100">
          <i @click="startTour" style="font-size: 20px; cursor: pointer" data-v-step="0" class="el-icon-help pr-3"></i>
        </el-tooltip>
        <el-tooltip content="Notificações" effect="light" :open-delay="100">
          <Notification class="pr-3" />
        </el-tooltip>
        <el-tooltip content="Alterar cor do tema" effect="light" :open-delay="100">
          <i @click="toggleMode(darkMode ? false : true)" class="pr-3" style="cursor: pointer; font-size: 20px"
            :class="darkMode ? 'el-icon-moon' : 'el-icon-sunny'"></i>
        </el-tooltip>
        <user-menu></user-menu>
      </div>
    </ul>
    <v-tour name="myTour" :steps="steps" :options="myOptions"></v-tour>
  </base-nav>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import { BaseNav } from "@/components";
import Configurations from "@/pages/configurations/Configurations";
import UserMenu from "../../components/Navbar/UserMenu";
import SidebarToggleButton from "./SidebarToggleButton";
import { EventBus } from "@/eventBus";
import { mapActions, mapState, mapMutations } from "vuex";
import Notification from "./Notification.vue";
import constants from "../../util/constants";

import vueTourMixin from "@/mixins/vueTourMixin";
const MODAL_WIDTH = 656;

export default {
  mixins: [vueTourMixin],
  components: {
    SidebarToggleButton,
    UserMenu,
    CollapseTransition,
    BaseNav,
    Configurations,
    Notification,
  },
  computed: {
    ...mapState("health", ["erp", "bahngleis", "serviceLayer", "serviceLayerXS"]),
    ...mapState(["ecommerceList"]),
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    ecommerceCurrent: {
      get() {
        return this.$store.state.ecommerce.Id;
      },
      set(value) {
        sessionStorage.setItem("ecommerceCurrent", value);
        this.$store.commit("updateEcommerce", value);
      },
    },
  },
  data() {
    return {
      darkMode: true,
      modalWidth: MODAL_WIDTH,
      status: "",
      statusBahngleis: false,
      statusErp: false,
      statusServiceLayer: false,
      statusServiceLayerXS: false,
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
      imageEcommerceCurrent: "",
      activities: [
        {
          content: "Custom icon",
          timestamp: "2018-04-12 20:46",
          color: "#0bbd87",
        },
        {
          content: "Custom color",
          timestamp: "2018-04-03 20:46",
          color: "#0bbd87",
        },
        {
          content: "Custom size",
          timestamp: "2018-04-03 20:46",
          size: "large",
        },
        {
          content: "Default node",
          timestamp: "2018-04-03 20:46",
        },
      ],
      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: "Pular",
          buttonPrevious: "Anterior",
          buttonNext: "Próximo",
          buttonStop: "Finalizar",
        },
      },
      canEditPrivateInformation: false
    };
  },
  mounted() {
    this.getStatus();
  },
  methods: {
    ...mapActions("health", [
      "getBahngleisStatus",
      "getErpStatus",
      "getServiceLayerStatus",
      "getServiceLayerXSStatus",
      "getConfigurationRestrictedData"
    ]),
    ...mapMutations('health', ['SET_CONFIGURATIONS_RESTRICTED_DATA']),
    startTour() {
      this.$tours["myTour"].start();
    },
    getPathImage(ecommerceId) {
      let ecommerce = ecommerceId ?
        this.ecommerceList.find(a => a.Id === ecommerceId)
        : this.ecommerceList.find(a => a.Id === this.$store.state.ecommerce.Id);
      let name = ecommerce && ecommerce.Platform ? this.getPlatformNames(ecommerce.Platform) : "bahn";
      return require(`@/assets/images/${name}.png`);
    },
    getPlatformNames(key) {
      return constants.EcommercePlatForm.find((a) => a.Value === key).Label.toLowerCase().replace(/\s/g, '');
    },
    checkThemeColor() {
      let darkMode = localStorage.getItem("darkMode");
      this.darkMode = darkMode === "true";
      this.toggleMode(this.darkMode);
    },
    changeEcommerce() {
      EventBus.$emit("changeEcommerce");
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      this.$keycloak.logout({ redirectUri: "www.nordware.io" });
    },
    toggleMode(type) {
      let docClasses = document.body.classList;

      if (type) {
        docClasses.remove("white-content");
        localStorage.setItem("darkMode", true);
        this.darkMode = true;
      } else {
        localStorage.setItem("darkMode", false);
        docClasses.add("white-content");
        this.darkMode = false;
      }

      EventBus.$emit("toggleMode", this.darkMode);
    },
    toggleRestrictedDataDisplay() {
      this.canEditPrivateInformation = !this.canEditPrivateInformation;
      this.SET_CONFIGURATIONS_RESTRICTED_DATA(this.canEditPrivateInformation);
      EventBus.$emit("toggleRestrictedDataDisplay", this.canEditPrivateInformation);
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    },
    getStatus() {
      const promises = [
        this.getBahngleisStatus(),
        this.getErpStatus(),
        this.getServiceLayerStatus(),
        this.getServiceLayerXSStatus(),
      ];

      Promise.all(promises).then(() => {
        this.statusBahngleis = this.bahngleis === "Healthy" ? true : false;
        this.statusErp = this.erp === "Healthy" ? true : false;
        this.statusServiceLayer = this.serviceLayer;
        this.statusServiceLayerXS = this.serviceLayerXS;

        if (
          this.statusBahngleis &&
          this.statusErp &&
          this.statusServiceLayer &&
          this.statusServiceLayerXS
        ) {
          this.status = "Normal";
          return;
        }
        if (this.statusBahngleis !== this.statusErp) {
          this.status = "Em manutenção";
          if (!this.statusErp) {
            this.$showError("Por favor, verifique sua conexão com o ERP.");
          }
          return;
        } else if (!this.statusBahngleis && !this.statusErp) {
          this.status = "Parado";
          return;
        }

        if (!this.statusServiceLayer) {
          this.status = "Falha na conexão com a Service Layer do SAP.";
          this.$showError("Por favor, verifique sua conexão com a Service Layer.");
          return;
        }

        if (!this.statusServiceLayerXS) {
          this.status = "Falha na conexão com a XS Engine do SAP.";
          this.$showError("Por favor, verifique sua conexão com a XS Engine.");
          return;
        }
      });
    },
  },
  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? MODAL_WIDTH / 2 : MODAL_WIDTH;
    this.checkThemeColor();
  },
};
</script>
<style lang="scss">
@import "~@/assets/sass/dashboard/custom/variables";

.item {
  margin-top: 10px;
  margin-right: 40px;
}

.top-navbar {
  top: 0px;
}

$background_color: #404142;
$github_color: #dba226;
$facebook_color: #3880ff;

.box {
  background: white;
  overflow: hidden;
  width: 656px;
  height: 400px;
  border-radius: 2px;
  box-sizing: border-box;
  box-shadow: 0 0 40px black;
  color: #8b8c8d;
  font-size: 0;

  .box-part {
    display: inline-block;
    position: relative;
    vertical-align: top;
    box-sizing: border-box;
    height: 100%;
    width: 50%;

    &#bp-right {
      background: url("/static/panorama.jpg") no-repeat top left;
      border-left: 1px solid #eee;
    }
  }

  .box-messages {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  .box-error-message {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    height: 0;
    line-height: 32px;
    padding: 0 12px;
    text-align: center;
    width: 100%;
    font-size: 11px;
    color: white;
    background: #f38181;
  }

  .partition {
    width: 100%;
    height: 100%;

    .partition-title {
      box-sizing: border-box;
      padding: 30px;
      width: 100%;
      text-align: center;
      letter-spacing: 1px;
      font-size: 20px;
      font-weight: 300;
    }

    .partition-form {
      padding: 0 20px;
      box-sizing: border-box;
    }
  }

  input[type="password"],
  input[type="text"] {
    display: block;
    box-sizing: border-box;
    margin-bottom: 4px;
    width: 100%;
    font-size: 12px;
    line-height: 2;
    border: 0;
    border-bottom: 1px solid #dddedf;
    padding: 4px 8px;
    font-family: inherit;
    transition: 0.5s all;
    outline: none;
  }

  button {
    background: white;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    letter-spacing: 1px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    min-width: 140px;
    margin-top: 8px;
    color: #8b8c8d;
    cursor: pointer;
    border: 1px solid #dddedf;
    text-transform: uppercase;
    transition: 0.1s all;
    font-size: 10px;
    outline: none;

    &:hover {
      border-color: mix(#dddedf, black, 90%);
      color: mix(#8b8c8d, black, 80%);
    }
  }

  .large-btn {
    width: 100%;
    background: white;

    span {
      font-weight: 600;
    }

    &:hover {
      color: white !important;
    }
  }

  .button-set {
    margin-bottom: 8px;
  }

  #register-btn,
  #signin-btn {
    margin-left: 8px;
  }

  .facebook-btn {
    border-color: $facebook_color;
    color: $facebook_color;

    &:hover {
      border-color: $facebook_color;
      background: $facebook_color;
    }
  }

  .github-btn {
    border-color: $github_color;
    color: $github_color;

    &:hover {
      border-color: $github_color;
      background: $github_color;
    }
  }

  .autocomplete-fix {
    position: absolute;
    visibility: hidden;
    overflow: hidden;
    opacity: 0;
    width: 0;
    height: 0;
    left: 0;
    top: 0;
  }
}

.pop-out-enter-active,
.pop-out-leave-active {
  transition: all 0.5s;
}

.pop-out-enter,
.pop-out-leave-active {
  opacity: 0;
  transform: translateY(24px);
}

.pipe {
  border-right: 1px solid #d7d7d7;
}

.bahn-logo {
  margin: 5% 18%;
}

.cls-1 {
  fill: url(#linear-gradient);
}

.cls-2 {
  fill: #fff;
}

.bg-white .bahn-logo .name {
  fill: #1e2543 !important;
}

.white-content .bahn-logo .name {
  fill: #1e2543 !important;
}

.icon-ecommerce {
  width: 35px;
  margin-top: 5px;
  box-shadow: -1px 1px 2px 0px #70707045;
  border-radius: 0.2857rem;
}

.icon-sub-ecommerce {
  width: 2em;
  margin-top: 0.3em;
  box-shadow: -1px 1px 2px 0px #70707045;
  border-radius: 0.2857rem;
}

#testeId {
  padding-left: 4em;
}

.bootstrap-switch {
  background: #07b197;
}

.bell {
  color: white;
  cursor: pointer;
  -webkit-animation: ring 3s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 3s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 3s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

.bell:hover {
  color: #1aac96;
}

.switch-custom {
  margin-top: 0.9em;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }

  1% {
    -moz-transform: rotate(30deg);
  }

  3% {
    -moz-transform: rotate(-28deg);
  }

  5% {
    -moz-transform: rotate(34deg);
  }

  7% {
    -moz-transform: rotate(-32deg);
  }

  9% {
    -moz-transform: rotate(30deg);
  }

  11% {
    -moz-transform: rotate(-28deg);
  }

  13% {
    -moz-transform: rotate(26deg);
  }

  15% {
    -moz-transform: rotate(-24deg);
  }

  17% {
    -moz-transform: rotate(22deg);
  }

  19% {
    -moz-transform: rotate(-20deg);
  }

  21% {
    -moz-transform: rotate(18deg);
  }

  23% {
    -moz-transform: rotate(-16deg);
  }

  25% {
    -moz-transform: rotate(14deg);
  }

  27% {
    -moz-transform: rotate(-12deg);
  }

  29% {
    -moz-transform: rotate(10deg);
  }

  31% {
    -moz-transform: rotate(-8deg);
  }

  33% {
    -moz-transform: rotate(6deg);
  }

  35% {
    -moz-transform: rotate(-4deg);
  }

  37% {
    -moz-transform: rotate(2deg);
  }

  39% {
    -moz-transform: rotate(-1deg);
  }

  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes alerta {
  0% {
    transform: scale();
  }

  70% {
    transform: scale();
    box-shadow: 0 0 0 50px rgba(69, 152, 27, 0);
  }

  100% {
    transform: scale();
    box-shadow: 0 0 0 0 rgba(69, 152, 27, 0);
  }
}

#notification {
  animation: alerta 1.5s infinite;
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}
</style>
