import actions from './actions'
import mutations from './mutations'

const state = {
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}