import constants from "@/util/constants";

const SET_ORDER = (state, ordersData) => {
    state.orders = ordersData;
}

const SET_ORDER_CURRENT = (state, order) => {
    state.orderCurrent = order;
}

const SET_ERP_DOCUMENTS = (state, erpDocuments) => {
    state.erpDocuments = erpDocuments;
}

const UPDATE_PROPERTY_ORDER = (state, payload) => {
    const { property, newValue } = payload;
    state.orderCurrent[property] = newValue;
}

const SET_NEW_CHANNEL = (state, channel) => {
    state.orderCurrent.Channel = channel;
}

const UPDATE_PROPERTY_ORDER_SHIPPING = (state, payload) => {
    const { property, newValue } = payload;
    state.orderCurrent.OrderAddress.forEach(address => {
        if(address.AddressType === constants.AddressType.Shipping){
            address[property] = newValue;
        }
    });
}

const UPDATE_PROPERTY_ORDER_PAYMENT = (state, payload) => {
    const { property, newValue } = payload;
    state.orderCurrent.OrderAddress.forEach(address => {
        if(address.AddressType === constants.AddressType.Billing){
            address[property] = newValue;
        }
    });
}

const UPDATE_PROPERTY_ORDER_CUSTOMER = (state, payload) => {
    const { property, newValue } = payload;
    state.orderCurrent.Customer[property] = newValue;
}

const UPDATE_PROPERTY_SHIPPING_METHOD = (state, value) => {
    state.orderCurrent.Shipping.Method = value;
}

const UPDATE_PROPERTY_PAYMENT_METHOD = (state, data) => {
    state.orderCurrent.OrderPayments.forEach(payment => {
        if(payment.Id === data.paymentId)
            payment.PaymentMethod = data.paymentMethod;
    });
}

const SET_ORDERS_SELECTED = (state, orders) => {
    state.ordersselected = orders;
}

const SET_LOG_INTERNAL = (state, log) => {
    state.logInternal = log;
}

const SET_HELP_CENTER = (state, helpCenters) => {
  state.helpCenter = helpCenters;
}

export default {
    SET_ORDER,
    SET_ORDER_CURRENT,
    SET_ERP_DOCUMENTS,
    SET_ORDERS_SELECTED,
    UPDATE_PROPERTY_ORDER,
    UPDATE_PROPERTY_ORDER_SHIPPING,
    UPDATE_PROPERTY_ORDER_PAYMENT,
    UPDATE_PROPERTY_ORDER_CUSTOMER,
    UPDATE_PROPERTY_SHIPPING_METHOD,
    UPDATE_PROPERTY_PAYMENT_METHOD,
    SET_LOG_INTERNAL,
    SET_HELP_CENTER,
    SET_NEW_CHANNEL
}
