const SET_USERS = (state, users) => {
    state.users = users;
}

const SET_USER_CURRENT = (state, user) => {
    state.userCurrent = user;
}

const SET_USERS_COUNT = (state, count) => {
    state.userCount = count;
}

const SET_USER_GROUPS = (state, groups) => {
    state.userGroups = groups;
}

const SET_LOGS = (state, logs) => {
    state.logs = logs
}

export default {
    SET_USERS,
    SET_USER_CURRENT,
    SET_USERS_COUNT,
    SET_USER_GROUPS,
    SET_LOGS
}