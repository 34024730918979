const updateEcommerce = (state, ecommerceid) => {
  state.ecommerce = state.ecommerceList.find(e => e.Id === ecommerceid);
  sessionStorage.setItem("ecommerce", JSON.stringify(state.ecommerce));
}

const updateEcommerceList = (state, ecommerceList) => {
  state.ecommerceList = ecommerceList;
}

const loading = (state, isLoading) => {
  if (isLoading) {
    state.refCount++;
    state.isLoading = true;
  } else if (state.refCount > 0) {
    state.refCount--;
    state.isLoading = state.refCount > 0;
  }
}

const SET_ECOMMERCE_LIST = (state, ecommerceList) => {
  state.ecommerceList = ecommerceList;
  state.ecommerce = ecommerceList[0];
}

const SET_BACKEND_ENDPOINTS = (state, realm) => {
  state.realm = realm;

  // if(process.env.true === "true"){
  //   state.bahngleisUrl = process.env.https://bahngleis.{realm}.bahn.nordware.io.replace("{realm}", realm);
  //   state.bahnhofUrl = process.env.VUE_APP_BAHNHOF.replace("{realm}", realm);
  //   console.info("Using multitenant");
  // }else{
  //   state.bahngleisUrl = process.env.https://bahngleis.{realm}.bahn.nordware.io;
  //   state.bahnhofUrl = process.env.VUE_APP_BAHNHOF;
  //   console.info("Using single tenant");
  // }

  // localStorage.setItem('endpointBahngleis', state.bahngleisUrl);
  // localStorage.setItem('endpointBahnhof', state.bahnhofUrl);
}

const ADD_MESSAGE = (state, payload) => {
  state.messages.push(payload);
}

const SET_ALL_ECOMMERCE = (state, allEcommerce) => {
  state.allEcommerce = allEcommerce;
}

const loadAccount = (state) => {
  let account = sessionStorage.getItem("account");
  let roles = sessionStorage.getItem("roles");

  if(roles){
    state.roles = roles;
  }

  if (account) {
    state.account = JSON.parse(account);
  }
}

export default{
  SET_ECOMMERCE_LIST,
  SET_BACKEND_ENDPOINTS,
  ADD_MESSAGE,
  updateEcommerce,
  updateEcommerceList,
  loading,
  ADD_MESSAGE,
  SET_ALL_ECOMMERCE,
  loadAccount
}
