import Vue from "vue";

const search = async ({ rootState }, params) => {
    params = params || {};
    params = Object.assign(params, { ecommerceName: rootState.ecommerce.Name });

    return await Vue.prototype.$bahngleis.get(`ExternalSearch/`, { params: params });
}

const getLogs = async ({ rootState, commit }) => {
    return await Vue.prototype.$bahngleis.get(`LogAction/getlogsofentity?identity=${rootState.ecommerce.Name}&entity=ExternalSearch`)
        .then(response => {
            commit('SET_LOGS', response.data);
        });
}

export default {
    getLogs,
    search
}