import actions from './actions'
import mutations from './mutations'


const state = {
    platforms : [],
    ecommerces: [],
    form : null,
    erp : {},
    ecommerce : {}
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}