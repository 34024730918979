<template>
  <div id="app">
    <loading-screen v-if="isLoading"></loading-screen>
    <router-view />
    <transition name="fade">
      <div id="pagetop" class="fixed right-0 bottom-5 scrollTop" v-show="scY > 300" @click="toTop">
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#4a5568"
          stroke-width="1" stroke-linecap="square" stroke-linejoin="arcs">
          <path d="M18 15l-6-6-6 6" />
        </svg>
      </div>
    </transition>
  </div>
</template>

<script>
import loadingScreen from "./components/Loading/Loading.vue";
import { mapState, mapMutations, mapActions } from "vuex";
import { messaging } from "@/firebase.js";
import { EventBus } from "@/eventBus";

export default {
  components: {
    loadingScreen,
  },
  data() {
    return {
      polling: null,
      db: null,
      scTimer: 0,
      scY: 0,
    };
  },
  computed: {
    ...mapState(["isLoading", "refCount"]),
  },
  methods: {
    ...mapMutations(['SET_ECOMMERCE_LIST', 'SET_BACKEND_ENDPOINTS']),
    ...mapActions(['sendRegistrationToken']),
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    errorModal(message) {
      this.$showError(message);
    },
    refreshToken() {
      let expiresIn =
        this.$keycloak.tokenParsed.exp -
        Math.ceil(new Date().getTime() / 1000) +
        this.$keycloak.timeSkew;

      this.polling = setInterval(() => {
        this.$keycloak
          .updateToken(180)
          .success((refreshed) => {
            if (refreshed) {
              console.log("Token refreshed: " + refreshed);
            } else {
              console.log(
                "Token not refreshed, valid for " +
                Math.round(secondTokenExpire) +
                " seconds"
              );
            }
          })
          .error(() => {
            console.log("Failed to refresh token");
          });
      }, expiresIn * 1000);
    },
    async getEcommerces() {

      try {
        await this.$bahngleis
          .get("Ecommerce/GetAll")
          .then(response => {
            if (!response.data || response.data.length === 0)
              this.$router.push({ name: 'Wizard' });

            this.SET_ECOMMERCE_LIST(response.data.filter(x => x.Active));
          });
      } catch (error) {
        console.log('Erro ao buscar os ecommerces:', error);

        const fakeList = [
          {
            Id: 1,
            Name: '-',
            Platform: 0,
            Active: true,
            Erp: {
              Id: 1
            }
          },
        ];
        this.SET_ECOMMERCE_LIST(fakeList);
      }
    },
    openDatabase() {
      const request = window.indexedDB.open("meuBancoDeDados", 1);
      request.onerror = () => {
        console.log("Database failed to open");
      };
      request.onsuccess = () => {
        this.db = request.result;
        console.log("Database opened successfully");
      };

      return new Promise((resolve) => {
        const checkVariable = () => {
          if (this.db) {
            resolve(this.db);
          } else {
            setTimeout(checkVariable, 100);
          }
        };
        checkVariable();
      });
    },
    getNotificationsInBackground() {
      if (!window.indexedDB || !window.localStorage) {
        console.log("Seu navegador não suporta o IndexedDB ou o localStorage.");
        return;
      }

      var request = window.indexedDB.open("meuBancoDeDados", 1);
      const notifications = [];

      request.onsuccess = function (event) {
        var db = event.target.result;

        var transaction = db.transaction(["meuObjeto"], "readonly");

        var objectStore = transaction.objectStore("meuObjeto");

        var cursorRequest = objectStore.openCursor();
        cursorRequest.onsuccess = function (event) {
          var cursor = event.target.result;

          if (cursor) {
            var dado = cursor.value.dado;
            notifications.push(dado);
            cursor.continue();
          } else {
            console.log("Dado transferido com sucesso do IndexedDB para o localStorage!");
            localStorage.setItem("notifications", JSON.stringify(notifications));
            EventBus.$emit("newNotifications");
          }
        };

        cursorRequest.onerror = function () {
          console.log("Erro ao obter os dados do IndexedDB.");
        };

      };

      request.onerror = function () {
        console.log("Erro ao abrir o banco de dados.");
      };
    },
    showNotifications(payload) {
      let notifications = [];
      if (Array.isArray(payload)) {
        notifications = payload;
      } else if (typeof payload === "object") {
        notifications.push(payload);
      }
      localStorage.setItem("notifications", JSON.stringify(notifications));
      EventBus.$emit("newNotifications");
    }
  },
  async created() {
    this.refreshToken();

    this.$bahngleis.interceptors.request.use(
      (config) => {
        this.$store.commit("loading", true);
        return config;
      },
      (error) => {
        this.$store.commit("loading", false);
        return Promise.reject(error);
      }
    );

    this.$nordware_config.interceptors.request.use(
      (config) => {
        this.$store.commit("loading", true);
        return config;
      },
      (error) => {
        this.$store.commit("loading", false);
        return Promise.reject(error);
      }
    );

    this.$bahngleis.interceptors.response.use(
      (response) => {
        this.$store.commit("loading", false);
        return response;
      },
      (error) => {
        this.$store.commit("loading", false);
        if (error.response.status === 401)
          this.errorModal("Credenciais inválidas.");
        else if (error.response.status === 403)
          this.errorModal("Você não tem autorização para realizar esta ação.");

        return Promise.reject(error);
      }
    );

    this.$nordware_config.interceptors.response.use(
      (response) => {
        this.$store.commit("loading", false);
        return response;
      },
      (error) => {
        this.$store.commit("loading", false);
        if (error.response.status === 401)
          this.errorModal("Credenciais inválidas.");
        else if (error.response.status === 403)
          this.errorModal("Você não tem autorização para realizar esta ação.");
        else
          this.errorModal(error.response.data);
        return Promise.reject(error);
      }
    );

    await this.getEcommerces();
  },
  mounted() {
    this.SET_BACKEND_ENDPOINTS(localStorage.getItem('realm'));
    window.addEventListener('scroll', this.handleScroll);
    messaging.requestPermission().then(() => {
      this.openDatabase().then(() => {
        this.getNotificationsInBackground();
      });

      messaging.getToken({ vapidKey: 'BJ9qFAkKYEZ25Bhxm-FswpbXTKaUAPPg9g67960atlosF-I4E4pMKHk63J2Gpk4Efn5FjxFEWR19k9HjpmCrGns' }).then(async (currentToken) => {
        if (currentToken) {
          console.log('Token FCM:', currentToken)
          // await this.sendRegistrationToken(currentToken);
        } else {
          console.log('Token FCM não disponível')
        }
      }).catch((err) => {
        console.log('Erro ao obter o token FCM:', err)
      });

      messaging.onMessage((payload) => {
        console.log('Mensagem recebida:', payload);
        this.showNotifications(payload.notification);
      });
    });
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
};
</script>
<style>
/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5c5c80;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #454563;
  border-radius: 10px;
}

.scrollTop{
  float: right;
  position: sticky;
  background: white;
  box-shadow: #b5b0b0 1px 0px 5px;
  position: sticky;
  margin-right: 10px;
  margin-bottom: 10px;
  box-shadow: #b5b0b0 1px 0px 5px;
  border-radius: 10px;
  cursor: pointer;
}
</style>
