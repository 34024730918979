const SET_ORGANIZATIONS = (state, organizations) => {
  state.organizations = organizations
}

const SET_LOGS = (state, logs) => {
  state.logs = logs
}

const SET_ORGANIZATION_CURRENT = (state, organization) => {
  state.organizationCurrent = organization;
}

export default {
  SET_ORGANIZATIONS,
  SET_LOGS,
  SET_ORGANIZATION_CURRENT
}
