const SET_USAGES = (state, usages) => {
    state.usages = usages
}

const SET_LOGS = (state, logs) => {
    state.logs = logs
}

const SET_USAGE_CURRENT = (state, usage) => {
    state.usageCurrent = usage;
}

export default {
    SET_USAGES,
    SET_LOGS,
    SET_USAGE_CURRENT
}