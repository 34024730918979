import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  form : {
    expenseCode: 1,
    orderDraft: false,
    shipToDefault: "",
    billToDefault: "",
    groupCode: 106,
    bpl_IdAssignedToInvoice: null,
    taxProductIntegrated: false,
    priceListName: null,
    currency: "R$",
    sellerName: null,
    comments: "",
    series: null,
    mainUsage: "",
    flagCep: false,
    enqueue: false,
    executeStockJobProduct: false,
    executePriceJobProduct: false,
    priceListNameDiscount: "",
    showWithoutStock: false,
    executeImageJob: false,
    languageCode: null,
    priceListNameB2B: ""
  },
  configurations : [],
  configurationGroup : [],
  recurringJobs : [],
  businessPartnerGroups: [],
  businessPartnerSeries: [],
  businessPlaces: [],
  companyInfo: [],
  priceLists: [],
  salesPersons: [],
  shippingCodes: [],
  channels : [
    {
        prop: 'Id',
        label: 'Id',
        minWidth: 50,
    },
    {
        prop: 'EcommerceChannel',
        label: 'Canal',
        minWidth: 100,
    },
    {
        prop: 'SAPChannel',
        label: 'Canal do SAP',
        minWidth: 75,
    },
    {
        prop: 'SAPStore',
        label: 'Store do SAP',
        minWidth: 75,
    },
    {
        prop: 'CustomerType',
        label: 'Tipo de Cliente',
        minWidth: 100,
    },
    {
        prop: 'CNPJ',
        label: 'CNPJ',
        minWidth: 100,
    },
    {
        prop: 'IndicatorOfPresence',
        label: 'Indicador de presença',
        minWidth: 100,
    },
  ],
  scheduleCurrent : null
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
