/*!

 =========================================================
 * Vue Black Dashboard PRO - v1.2.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-pro
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import DashboardPlugin from "./plugins/dashboard-plugin";
import App from "./App.vue";
import VModal from "vue-js-modal";
import store from './store';
import { sync } from 'vuex-router-sync';
import routers from "./routes/router";
import './util/prototype';
import "./plugins/keycloak";
import './util/filters';
import "./plugins/axios";
import "./registerServiceWorker";
import ToggleButton from "vue-js-toggle-button";
import vueDebounce from 'vue-debounce';
import Element from 'element-ui';
import locale from 'element-ui/lib/locale/lang/pt-br';
import VueMask from 'v-mask';
import Icon from './components/Icons/Icon.vue';
import VueTour from 'vue-tour'
require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)
Vue.use(Element, {locale});
Vue.use(ToggleButton);
Vue.use(vueDebounce, {
  defaultTime: '700ms'
});
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(VModal);
Vue.directive('mask', {
  bind: function (el, binding) {
    Inputmask(binding.value).mask(el.getElementsByTagName('INPUT')[0])
  }
});

Vue.use(VueMask);

sync(store, routers);

Vue.mixin({
  beforeMount() {
    let ecommerceCurrent = sessionStorage.getItem("ecommerceCurrent");
    if(ecommerceCurrent)
      store.commit("updateEcommerce", parseInt(ecommerceCurrent));
  }
});

Vue.component('icon', Icon);

Vue.prototype.$keycloak
  .init({ onLoad: "login-required" })
  .success(auth => {
    if (!auth) {
      window.location.reload();
    }
    new Vue({
      el: "#app",
      render: h => h(App),
      router: routers,
      store
    });
  })
  .error(error => {
    alert("failed to initialize - " + error);
  });

