import actions from "./actions";
import mutations from './mutations';

const state = {
    groups: [],
    groupCurrent: {},
    groupPermissions: {},
    groupCount: 0,
    groupClientsPermissions: {},
    clientRole: {},    
    logs: []
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}