<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="copyright d-flex flex-column">
        <span>
          &copy; {{ year }} - made by
          <a href="https://nordware.io" rel="noopener" target="_blank">Nordware</a>
        </span>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
