const getConfigurationByGroup = (state) => (group) => {
    return state.configurations.filter(c => c.Group === group).sort(function(a, b) {
        if (a.FieldType < b.FieldType) {
          return 1;
        }
        if (a.FieldType > b.FieldType) {
          return -1;
        }
        return 0;
      });
};

export default {
    getConfigurationByGroup
}