const SET_ADDONS = (state, addons) => {
    state.addons = addons
}

const SET_ADDON_CURRENT = (state, addon) => {
    state.addonCurrent = addon;
}

const SET_LOGS = (state, logs) => {
    state.logs = logs
}

export default {
    SET_ADDONS,
    SET_ADDON_CURRENT,
    SET_LOGS
}