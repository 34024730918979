import actions from "./actions";
import mutations from './mutations';
import getters from "./getters";

const state = {
  paymentMethods: [],
  paymentMethodCurrent: {},
  logs: [],
  creditCardCodes: [],
  paymentMethodTypes: [],
  paymentMethodCodes: [],
  erpPlatform: []
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
