const SET_BAHNGLEIS_STATUS = (state, bahngleis) => {
    state.bahngleis = bahngleis
}

const SET_ERP_STATUS = (state, erp) => {
    state.erp = erp
}

const SET_SL_STATUS = (state, serviceLayer) => {
    state.serviceLayer = serviceLayer
}

const SET_XS_STATUS = (state, serviceLayerXS) => {
    state.serviceLayerXS = serviceLayerXS
}

const SET_CONFIGURATIONS_RESTRICTED_DATA = (state, displayRestrictedData) => {
    state.displayRestrictedData = displayRestrictedData;
}

export default {
    SET_BAHNGLEIS_STATUS,
    SET_ERP_STATUS,
    SET_SL_STATUS,
    SET_XS_STATUS,
    SET_CONFIGURATIONS_RESTRICTED_DATA
}
