const SET_PLATFORMS = (state, model) => {
    state.platforms = model;
}

const SET_ECOMMERCES = (state, model) => {
    state.ecommerces = model;
}

const SET_ERP = (state, model) => {
    state.erp = model;
    localStorage.setItem("erp-new-client", model);
}

const SET_ECOMMERCE = (state, model) => {
    state.ecommerce = model;
}

export default {
    SET_PLATFORMS,
    SET_ECOMMERCES,
    SET_ERP,
    SET_ECOMMERCE
}