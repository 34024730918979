import actions from "./actions";
import mutations from './mutations';

const state = {
    users: [],
    userCurrent : {},
    userCount : 0,
    userGroups: []
}

export default {
    namespaced : true,
    state,
    actions,
    mutations
}