import actions from './actions';
import mutations from './mutations';
import getters from './getters'


const state = {
  ordersData: [],
  erpDocuments: [],
  orderCurrent: {
    Id: null,
    Anonymized: false,
    OrderType: null,
    DocEntry: null,
    EcommerceName: null,
    Channel: null,
    CustomerEcommerceID: null,
    Number: null,
    OrderEcommerceID: null,
    PurchaseDate: null,
    IntegratedDate: null,
    ImportedDate: null,
    SellerName: null,
    BplID: null,
    BrandName: null,
    WarehouseCode: null,
    StatusID: null,
    NFKey: null,
    NFNumber: null,
    DocEntryNf: null,
    DocEntryPicking: null,
    DocEntryCR: null,
    DocEntryAdto: null,
    DocEntryNfTransfer: null,
    DocEntryPurchaseDeliveryTransfer: null,
    DocEntryPurchaseInvoiceTransfer: null,
    CustomPlatformFields: null,
    ContainsItensTransfer: null,
    DocNumOrder: null,
    TrackingCode: null,
    CardCode: null,
    Shipping: {
      Id: null,
      Method: null,
      ErpReference: null,
      IsDropShipping: null,
      Price: null,
      PackagesCount: null,
      QuotedPrice: null,
      DueDate: null,
      MethodType: null,
      Address: {
        Id: null,
        Name: null,
        Street: null,
        Number: null,
        District: null,
        City: null,
        Country: null,
        CountryCode: null,
        UF: null,
        ZipCode: null,
        Phone: null,
        Mobile: null,
        TaxIdentification: null,
        AddressType: null
      }
    },
    Payment: {
      Id: null,
      Method: null,
      Total: null,
      Discount: 0,
      Additional: 0,
      Installments: null,
      DueDate: null,
      TransactionCode: null,
      Address: {
        Id: 26778,
        Name: null,
        Street: null,
        Number: 10,
        District: null,
        City: null,
        Country: null,
        CountryCode: null,
        UF: null,
        ZipCode: null,
        Phone: null,
        Mobile: null,
        TaxIdentification: null,
        AddressType: null
      },
      CreditCardBrand: null,
      NSU: null,
      CouponCode: null,
      CouponDescription: null,
      GiftVoucher: null,
      SubTotal: null,
      BoletoTaxAmount: 0,
      TaxReplacementIcms: 0,
      TaxReplacementDifal: 0,
      TaxIpi: 0
    },
    Customer: {
      Id: null,
      Name: null,
      Alias: null,
      Role: null,
      Email: null,
      TaxIdentification: null,
      PhoneNumber: null,
      BirthDate: null,
      GroupCode: 0,
      Reseller: {
        Id: null,
        StateRegistry: null,
        CorporateName: null,
        FilledByNuuk: false
      }
    },
    Products: [
    ],
    Status: {
      ID: null,
      EcommerceName: null,
      AliasName: null,
      BahnReference: null,
      EcommerceReference: null,
      ErpReference: null
    },
    Documents: [
    ],
    UpdateReason: null,
  },
  orders: [],
  ordersselected: [],
  logInternal: {},
  helpCenter: []
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
