import actions from "./actions";
import mutations from './mutations';

const state = {
    observationDocuments: [],
    logs: [],
    observationDocumentCurrent : {}
}

export default {
    namespaced : true,
    state,
    actions,
    mutations
}