import Vue from "vue";
import Vuex from "vuex";

import getters from './getters';
import actions from './actions';
import mutations from './mutations';

import addon from '../pages/configurations/Addon/store';
import cleaningLog from '../pages/CleaningLog/store';
import configurations from '../pages/configurations/store';
import customEvent from '../pages/CustomEvent/store';
import customfield from '../pages/customfields/store';
import ecommerceView from '../pages/Ecommerce/store';
import fulfillment from '../pages/Fulfillment/store';
import group from '../pages/Group/store';
import logOrder from '../pages/Logs/store';
import notificationEmail from '../pages/configurations/Notifications/store';
import observationDocument from '../pages/ObservationDocument/store';
import orders from '../pages/Orders/store';
import usage from '../pages/Usage/store';
import log from '../pages/Logs/store';
import wizard from '../pages/Wizard/store';
import user from '../pages/User/store';
import costCenter from '../pages/CostCenter/store';
import product from '../pages/Product/store';
import health from '../pages/Layout/store';
import paymentMethod from '../pages/PaymentMethods/store';
import schedules from '../pages/schedules/store';
import commandbridge from '../pages/commandbridge/store';
import organization from '../pages/Organization/store';
import priceList from '../pages/PriceList/store';
import externalSearch from '../pages/ExternalSearch/store';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    addon,
    cleaningLog,
    configurations,
    customEvent,
    customfield,
    ecommerceView,
    fulfillment,
    group,
    logOrder,
    notificationEmail,
    observationDocument,
    orders,
    usage,
    log,
    wizard,
    user,
    costCenter,
    product,
    health,
    paymentMethod,
    schedules,
    commandbridge,
    organization,
    priceList,
    externalSearch
  },
  state : {
    company: null,
    ecommerceList: [],
    ecommerce: {},
    isLoading: false,
    refCount: 0,
    realm : process.env.bahn,
    bahngleisUrl: null,
    bahnhofUrl: null,
    messages: [],
    allEcommerce:false
  },
  getters,
  actions,
  mutations,
})

export default store;
