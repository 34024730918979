import Vue from "vue";

const getCleaningLogs = async ({ rootState, commit }) => {
    return await Vue.prototype.$bahngleis.get(`CleaningLog/GetAll`)
        .then(res => {
            commit('SET_CLEANINGLOGS', res.data);
            return Promise.resolve(res)
        })
        .catch(err => { return Promise.reject(err.response.data) });;
}

const getLogs = async ({ rootState, commit }, id) => {
    return await Vue.prototype.$bahngleis.get(`LogAction/getlogsofentity?identity=CleaningLog&entity=CleaningLog`)
        .then(response => {
            commit('SET_LOGS', response.data);
        });
}

const updateCleaningLogs = ({ commit }, payload) => {
    return Vue.prototype.$bahngleis
        .put(`CleaningLog`, payload)
        .then(res => { return Promise.resolve(res) })
        .catch(err => { return Promise.reject(err.response.data) });
}

const startProcessGoLive = async ({ rootState }) => {
    return await Vue
        .prototype
        .$bahngleis
        .post(`/CleaningLog/StartGoLive?ecommerceName=${rootState.ecommerce.Name}`)
        .then(res => { return Promise.resolve(res) })
        .catch(err => { return Promise.reject(err.response.data) });
}

export default {
    getCleaningLogs,
    getLogs,
    updateCleaningLogs,
    startProcessGoLive
}
