import Bahngleis from "@/apis/bahngleis";
const bahngleis = new Bahngleis();

const getPriceListById = async ({ rootState, commit }, id) => {
  return await bahngleis.get(`SkuPriceList/${id}`).then(response => {
    commit('SET_PRICE_LIST_CURRENT', response.data);
    return response;
  });
}

const getPriceListsByEcommerce = async ({ rootState, commit }, params) => {
  params = params || {};
  params = Object.assign(params, { EcommerceName: rootState.ecommerce.Name });
  return await bahngleis.get(`SkuPriceList/GetPaginated`, { params: params }).then(response => {
    commit('SET_PRICE_LISTS', response.data.Data);
    return response;
  });
}

const getErpPriceListsByEcommerce = async ({ commit }, ecommerceName) => {
  return await bahngleis.get(`Configuration/GetErpPriceLists?ecommerceName=${ecommerceName}`)
    .then(response => {
      commit('SET_ERP_PRICE_LISTS', response.data);
      return response;
    });
}

const removePriceList = async ({ rootState }, id) => {
  return await bahngleis
    .delete(`/SkuPriceList/${id}`)
    .then(res => { return Promise.resolve(res) })
    .catch(err => { return Promise.reject(err.response.data) });
}

const getPriceListLog = async ({ commit }, id) => {
  return await bahngleis
    .get(`/LogAction/getlogsofentity?identity=${id}&entity=PriceList`)
    .then(response => {
      commit('SET_LOGS', response.data);
    });
}

const savePriceList = async ({ rootState }, payload) => {
  payload = Object.assign(payload, { ecommerceName: rootState.ecommerce.Name });
  return await bahngleis
    .post(`SkuPriceList/`, payload);
}

const updatePriceList = async ({ rootState }, payload) => {
  return await bahngleis
    .put(`SkuPriceList/${payload.id}`, payload);
}

export default {
  getPriceListById,
  getPriceListsByEcommerce,
  removePriceList,
  getPriceListLog,
  savePriceList,
  updatePriceList,
  getErpPriceListsByEcommerce
}
