<template>
    <el-popover placement="bottom" title="Notificações" width="450" trigger="click">
        <div class="card-notification" v-if="!existsNotifications">
            <p style="font-size:10pt; color:#8997a3"><strong>Sem notificações...</strong></p>
        </div>
        <div style="max-height: 200px;overflow-y: scroll;" v-if="existsNotifications">
            <div class="card-notification" v-for="(notification, index) in notifications" :key="index">
                <p style="font-size:10pt; color:#8997a3" id="notification-title"><strong>{{ notification.title }}</strong>
                </p>
                <p style="font-size:10pt; color:#8997a3">{{ notification.body }}</p>
            </div>
        </div>
        <div style="height:3em" align="center" v-if="existsNotifications">
            <a style="margin-top: 0.5em;" href="javascript:void(0)" @click="markReadAll"><small><strong>Marcar todas como
                        lida</strong></small></a>
        </div>
        <a slot="reference" v-bind:class="{ 'animation': existsNotifications, 'botao': true }"
            target="_blank">
            <i class="el-icon-bell" style="font-size:20px; cursor: pointer"></i>
        </a>
    </el-popover>
</template>
<script>
import { EventBus } from "@/eventBus";

export default {
    data() {
        return {
            notifications: [],
        }
    },
    computed: {
        existsNotifications() {
            return this.notifications.length > 0;
        }
    },
    methods: {
        markReadAll() {
            localStorage.removeItem("notifications");
            this.notifications = [];
            this.clearTable();
        },
        newNotifications() {
            let notifications = localStorage.getItem("notifications");
            this.notifications = JSON.parse(notifications);
            this.$showInfo(`Você tem ${this.notifications.length} ${this.notifications.length > 1 ? 'novas notificações!' : 'nova notificação!'}`);
        },
        clearTable() {
            var request = window.indexedDB.open("meuBancoDeDados", 1);

            request.onsuccess = function (event) {
                var db = event.target.result;
                var transaction = db.transaction(["meuObjeto"], "readwrite");
                var objectStore = transaction.objectStore("meuObjeto");
                const request = objectStore.clear();
                request.onsuccess = () => {
                    console.info(`All records in mydatabase have been deleted`);
                };
                request.onerror = () => {
                    console.info(`Failed to clear notifications table`);
                };
            }
        },
    },
    mounted() {
        let notifications = localStorage.getItem("notifications");
        if (notifications)
            this.notifications = JSON.parse(notifications);

        EventBus.$on("newNotifications", () => this.newNotifications());
    },
}
</script>
<style>
.el-popover {
    padding: 0px !important;
}

.el-popover__title {
    color: #727d8a;
    font-weight: 600;
    padding-top: 0.7em;
    text-align: center;
}

#notification-title {
    color: grey
}

.card-notification {
    width: 100%;
    background-color: #e9eff2;
    border-top: 1px solid transparent;
    border-color: #c3cfd7e0;
    padding: 12px;
}

.animation {
    animation: alerta 1.5s infinite;
}

.botao {
    color: white;
}

@keyframes alerta {
    0% {
        transform: scale();
    }

    70% {
        transform: scale();
        box-shadow: 0 0 0 50px rgba(69, 152, 27, 0);
    }

    100% {
        transform: scale();
        box-shadow: 0 0 0 0 rgba(69, 152, 27, 0);
    }
}
</style>