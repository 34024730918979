// GeneralViews
import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import Vue from 'vue';
// Dashboard pages
const Addon = () => import('src/pages/configurations/Addon/views/Addon.vue');
const AddonCreate = () => import('src/pages/configurations/Addon/views/AddonCreate.vue');
const AddonDetail = () => import('src/pages/configurations/Addon/views/AddonDetail.vue');
const Account = () => import('src/pages/Account/views/Account.vue');
const Branding = () => import('src/pages/Branding/Branding.vue');
const CleaningLog = () => import('src/pages/CleaningLog/views/CleaningLog.vue');
const Configurations = () => import('src/pages/configurations/Configurations.vue');
const County = () => import('src/pages/County/County.vue');
const CustomEvent = () => import('src/pages/CustomEvent/views/CustomEvent.vue');
const CustomEventCreate = () => import('src/pages/CustomEvent/views/CustomEventCreate.vue');
const CustomEventDetail = () => import('src/pages/CustomEvent/views/CustomEventDetail.vue');
const CustomField = () => import('src/pages/customfields/views/CustomField.vue');
const CustomFieldCreate = () => import('src/pages/customfields/views/CustomFieldCreate.vue');
const CustomFieldDetail = () => import('src/pages/customfields/views/CustomFieldDetail.vue');
const Dashboard = () => import('src/pages/Dashboard/Dashboard.vue');
const DeParaProductSync = () => import('src/pages/Product/DeParaProductSync/DeParaProductSync.vue');
const Ecommerce = () => import('src/pages/Ecommerce/Ecommerce.vue');
const EcommerceCreate = () => import('src/pages/Ecommerce/EcommerceCreate.vue');
const EcommerceDetail = () => import('src/pages/Ecommerce/EcommerceDetail.vue');
const ERPTest = () => import('src/pages/Health/ERPTest.vue');
const ExportProduct = () => import('src/pages/Export/Products.vue');
const Fulfillment = () => import('src/pages/Fulfillment/views/Fulfillment.vue');
const FulfillmentConfiguration = () => import('src/pages/Fulfillment/views/FulfillmentConfiguration.vue');
const FulfillmentConfigurationCreate = () => import('src/pages/Fulfillment/views/FulfillmentConfigurationCreate.vue');
const FulfillmentConfigurationDetail = () => import('src/pages/Fulfillment/views/FulfillmentConfigurationDetail.vue');
const FulfillmentDetail = () => import('src/pages/Fulfillment/views/FulfillmentDetail.vue');
const GeralConfigurations = () => import('src/pages/configurations/ConfigurationsGeneral.vue');
const Group = () => import('src/pages/Group/views/Group.vue');
const GroupCode = () => import('src/pages/GroupCode/GroupCode.vue');
const GroupCreate = () => import('src/pages/Group/views/GroupCreate.vue');
const GroupDetail = () => import('src/pages/Group/views/GroupDetail.vue');
const InternalLog = () => import('src/pages/Message/InternalMessage.vue');
const LogAction = () => import('src/pages/Logs/views/LogAction.vue');
const LogOrder = () => import('src/pages/Logs/views/LogOrder.vue');
const LogPrice = () => import('src/pages/Logs/LogPrice.vue');
const LogStock = () => import('src/pages/Logs/LogStock.vue');
const ManageDocuments = () => import('src/pages/configurations/ManageDocuments/ManageDocuments.vue');
const ManageSaleChannels = () => import('src/pages/configurations/SaleChannels.vue');
const ManualCaptureLog = () => import('src/pages/Logs/views/ManualCaptureLog.vue');
const ManualImport = () => import('src/pages/Product/ManualImport/ManualImport.vue');
const Message = () => import(/* webpackChunkName: "message" */ 'src/pages/Message/Message.vue');
const NotificationEmail = () => import('src/pages/configurations/Notifications/views/NotificationEmail.vue');
const NotificationEmailCreate = () => import('src/pages/configurations/Notifications/views/NotificationEmailCreate.vue');
const NotificationEmailDetail = () => import('src/pages/configurations/Notifications/views/NotificationEmailDetail.vue');
const NotificationEmailLog = () => import('src/pages/configurations/Notifications/views/NotificationEmailLog.vue');
const ObservationDocument = () => import('src/pages/ObservationDocument/views/ObservationDocument.vue');
const ObservationDocumentCreate = () => import('src/pages/ObservationDocument/views/ObservationDocumentCreate.vue');
const ObservationDocumentDetail = () => import('src/pages/ObservationDocument/views/ObservationDocumentDetail.vue');
const OrderDetails = () => import(/* webpackChunkName: "message" */ 'src/pages/Orders/views/OrderDetail.vue');
const Orders = () => import('src/pages/Orders/views/Orders.vue');
const UpdateWarehouse = () => import('src/pages/Orders/components/UpdateWarehouse.vue');
const PaymentMethods = () => import('src/pages/PaymentMethods/views/PaymentMethodList.vue');
const PaymentMethodCreate = () => import('src/pages/PaymentMethods/views/PaymentMethodCreate.vue');
const PaymentMethodEdit = () => import('src/pages/PaymentMethods/views/PaymentMethodEdit.vue');
const ProductImportSku = () => import('src/pages/Product/ImportSku/ImportSku.vue');
const ProductVariation = () => import('src/pages/Product/Variations/VariationGrid.vue');
const ProductVariationAttach = () => import('src/pages/Product/Variations/VariationAttach.vue');
const ProductVariationAttribute = () => import('src/pages/Product/Variations/VariationAttribute.vue');
const ProductList = () => import('src/pages/Product/views/ProductList');
const Skus = () => import('src/pages/Product/components/list/Skus');
const SkusLongTail = () => import('src/pages/Product/components/list/SkusLongTail');
const ProductIntegrationLog = () => import('src/pages/Product/views/ProductIntegrationLog');
const ProductRegister = () => import('src/pages/Product/views/ProductRegister');
const ProductEdit = () => import('src/pages/Product/views/ProductEdit');
const AttributeCreateGroup = () => import('src/pages/Product/views/AttributeCreateGroup');
const Attributes = () => import('src/pages/Product/views/Attributes');
const AttributeCreate = () => import('src/pages/Product/views/AttributeCreate');
const AttributeDetail = () => import('src/pages/Product/views/AttributeDetail');
const SkuDetail = () => import('src/pages/Product/views/ProductSkuEdit');
const Seller = () => import('src/pages/Seller/Seller.vue');
const ShippingMethods = () => import('src/pages/ShippingMethods/ShippingMethods.vue');
const ShippingType = () => import('src/pages/ShippingType/ShippingType.vue');
const Status = () => import('src/pages/Status/Status.vue');
const SyncPrice = () => import('src/pages/commandbridge/views/SyncPrice.vue');
const SyncStatus = () => import('src/pages/commandbridge/views/SyncStatus.vue');
const SyncIncomingPayment = () => import('src/pages/commandbridge/views/SyncIncomingPayment.vue');
const Schedules = () => import('src/pages/schedules/views/Schedules.vue');
const ScheduleDetail = () => import('src/pages/schedules/views/ScheduleDetail.vue');
const SyncStock = () => import('src/pages/commandbridge/views/SyncStock.vue');
const SyncOrder = () => import('src/pages/commandbridge/views/SyncOrder.vue');
const CommandBridge = () => import('src/pages/commandbridge/views/CommandBridge.vue');
const Usage = () => import('src/pages/Usage/views/Usage.vue');
const UsageCreate = () => import('src/pages/Usage/views/UsageCreate.vue');
const UsageDetail = () => import('src/pages/Usage/views/UsageDetail.vue');
const PushNotification = () => import('src/pages/configurations/Notifications/views/PushNotification.vue');
const User = () => import('src/pages/User/views/User.vue');
const UserCreate = () => import('src/pages/User/views/UserCreate.vue');
const UserDetail = () => import('src/pages/User/views/UserDetail.vue');
const WahrehouseConfigurations = () => import('src/pages/configurations/Warehouse/grid.vue');
const WahrehouseConfigurationsEdit = () => import('src/pages/configurations/Warehouse/edit.vue');
const WahrehouseConfigurationsNew = () => import('src/pages/configurations/Warehouse/new.vue');
const ListFileImports = () => import('src/pages/Product/views/ListFileImports.vue');
const DetailFileImport = () => import('src/pages/Product/views/DetailFileImport.vue');
const Wizard = () => import('src/pages/Wizard/views/Wizard.vue')
const LogOrderModal = () => import('src/pages/Orders/components/OrdersLogModal.vue');
const OrderEventsModal = () => import('src/pages/Orders/components/OrderEventsModal.vue');
const CustomFieldsModal = () => import('src/pages/Orders/components/CustomFieldsModal.vue');
const LogOrderFulfillmentModal = () => import('src/pages/Fulfillment/components/FulfillmentLogModal.vue');
const CostCenterConfiguration = () => import('src/pages/CostCenter/views/CostCenterConfiguration.vue');
const CostCenterConfigurationCreate = () => import('src/pages/CostCenter/views/CostCenterConfigurationCreate.vue');
const CostCenterConfigurationDetail = () => import('src/pages/CostCenter/views/CostCenterConfigurationDetail.vue');
const DocumentJson = () => import('src/pages/Orders/components/DocumentJson.vue');
const DocumentFulfillmentJson = () => import('src/pages/Fulfillment/components/DocumentJson.vue');
const ExportProductModal = () => import('src/pages/Product/components/ExportProductModal.vue');
const OrderRuleCreate = () => import('src/pages/customfields/views/OrderRuleCreate.vue');
const OrderRuleDetail = () => import('src/pages/customfields/views/OrderRuleDetail.vue');
const Onboarding = () => import('src/pages/Onboarding/Onboarding.vue');
const Organizations = () => import('src/pages/Organization/views/OrganizationList.vue');
const OrganizationCreate = () => import('src/pages/Organization/views/OrganizationCreate.vue');
const OrganizationDetail = () => import('src/pages/Organization/views/OrganizationEdit.vue');
const BuildReturnInvoicesFileModal = () => import('src/pages/Orders/components/BuildReturnInvoicesFileModal.vue');


const PriceLists = () => import('src/pages/PriceList/views/PriceLists.vue');
const PriceListCreate = () => import('src/pages/PriceList/views/PriceListCreate.vue');
const PriceListDetail = () => import('src/pages/PriceList/views/PriceListEdit.vue');

const ExternalSearch = () => import('src/pages/ExternalSearch/views/ExternalSearch.vue');

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/Dashboard',
    name: 'Dashboard layout',
    children: [
      {
        path: 'Dashboard',
        name: 'Dashboard',
        components: { default: Dashboard }
      },
      {
        path: 'Orders',
        name: 'Orders',
        components: { default: Orders },
        children: [
          {
            path: 'Logs',
            name: 'LogsOrder',
            components: { default: LogOrderModal }
          },
          {
            path: 'DocumentJson',
            name: 'DocumentJson',
            components: { default: DocumentJson }
          },
          {
            path: 'UpdateWarehouse',
            name: 'UpdateWarehouse',
            components: { default: UpdateWarehouse }
          },
          {
            path: 'sync-order',
            name: 'OrderSyncOrder',
            components: { default: SyncOrder }
          },
          {
            path: 'BuildReturnInvoicesFile',
            name: 'BuildReturnInvoicesFile',
            components: { default: BuildReturnInvoicesFileModal }
          },
        ]
      },
      {
        path: 'Orders/Details/:ecommerceName/:id',
        name: 'Details',
        components: { default: OrderDetails },
        children: [
          {
            path: 'order-custom-fields',
            name: 'OrderCustomFields',
            components: { default: CustomFieldsModal }
          },
          {
            path: 'order-events',
            name: 'OrderEvents',
            components: { default: OrderEventsModal }
          }
        ]
      },
      {
        path: 'ShippingMethods',
        name: 'ShippingMethods',
        components: { default: ShippingMethods }
      },
      {
        path: 'County',
        name: 'County',
        components: { default: County }
      },
      {
        path: 'Shippingtype',
        name: 'ShippingType',
        components: { default: ShippingType }
      },
      {
        path: 'Seller',
        name: 'Seller',
        components: {
          default: Seller
        }
      },
      {
        path: 'PaymentMethods',
        name: 'PaymentMethods',
        components: { default: PaymentMethods }
      },
      {
        path: 'PaymentMethods/create',
        name: 'PaymentMethodCreate',
        components: { default: PaymentMethodCreate }
      },
      {
        path: 'PaymentMethods/edit',
        name: 'PaymentMethodEdit',
        components: { default: PaymentMethodEdit }
      },
      {
        path: 'Status',
        name: 'Status',
        components: { default: Status }
      },
      {
        path: 'Product',
        name: 'Product',
        components: { default: ProductImportSku }
      },
      {
        path: 'Product/ImportSku',
        name: 'ProductImportSku',
        components: { default: ProductImportSku }
      },
      {
        path: 'Product/DeParaProductSync',
        name: 'DeParaProductSync',
        components: { default: DeParaProductSync }
      },
      {
        path: 'product/variation',
        name: 'ProductVariation',
        components: { default: ProductVariation }
      },
      {
        path: 'product/variation/attach/:id',
        name: 'ProductVariationAttach',
        components: { default: ProductVariationAttach }
      },
      {
        path: 'product/variation/attach/:id/variation',
        name: 'ProductVariationAttribute',
        components: { default: ProductVariationAttribute }
      },
      {
        path: 'Configurations',
        name: 'Configurations',
        components: { default: Configurations }
      },
      {
        path: 'configuration/Addon',
        name: 'Addon',
        components: { default: Addon }
      },
      {
        path: 'configuration/Addon/create',
        name: "AddonCreate",
        components: { default: AddonCreate },
      },
      {
        path: 'configuration/Addon/detail',
        name: "AddonDetail",
        components: { default: AddonDetail }
      },
      {
        path: 'configuration/warehouse',
        name: "WarehouseConfiguration",
        components: { default: WahrehouseConfigurations }
      },
      {
        path: 'configuration/warehouse/new',
        name: "WarehouseConfigurationNew",
        components: { default: WahrehouseConfigurationsNew }
      },
      {
        path: 'configuration/warehouse/edit/:id',
        name: "WarehouseConfigurationEdit",
        components: { default: WahrehouseConfigurationsEdit }
      },
      {
        path: 'configuration/geral',
        name: "GeralConfigurations",
        components: { default: GeralConfigurations },
      },
      {
        path: 'Health',
        name: "ERPTest",
        components: { default: ERPTest }
      },
      {
        path: 'Export/ExportProductsImages',
        name: "ExportProducts",
        components: { default: ExportProduct }
      },
      {
        path: 'configuration/Ecommerce',
        name: "Ecommerce",
        components: { default: Ecommerce }
      },
      {
        path: 'configuration/ecommerce/create',
        name: "EcommerceCreate",
        components: { default: EcommerceCreate },
        beforeEnter: (to, from, next) => {
          Vue.prototype.$keycloak.hasRealmRole("00_c") ? next() : next({ name: 'Unauthorized' })
        }
      },
      {
        path: 'configuration/ecommerce/detail',
        name: "EcommerceDetail",
        components: { default: EcommerceDetail }
      },
      {
        path: 'GroupCode',
        name: "GroupCode",
        components: { default: GroupCode }
      },
      {
        path: 'Branding',
        name: "Branding",
        components: { default: Branding }
      },
      {
        path: 'configuration/NotificationEmail',
        name: 'NotificationEmail',
        components: { default: NotificationEmail }
      },
      {
        path: 'configuration/NotificationEmail/create',
        name: "NotificationEmailCreate",
        components: { default: NotificationEmailCreate },
      },
      {
        path: 'configuration/NotificationEmail/detail',
        name: "NotificationEmailDetail",
        components: { default: NotificationEmailDetail }
      },
      {
        path: 'configuration/NotificationEmail/history',
        name: "NotificationEmailLog",
        components: { default: NotificationEmailLog }
      },
      {
        path: 'Logs/LogPrice',
        name: "LogPrice",
        components: { default: LogPrice },
        children: [
          {
            path: 'sync-price',
            name: 'LogSyncPrice',
            components: { default: SyncPrice }
          },
        ]
      },
      {
        path: 'Logs/ManualCaptureLog',
        name: "ManualCaptureLog",
        components: { default: ManualCaptureLog }
      },
      {
        path: 'Logs/LogStock',
        name: "LogStock",
        components: { default: LogStock },
        children: [
          {
            path: 'sync-stock',
            name: 'LogSyncStock',
            components: { default: SyncStock }
          }
        ]
      },
      {
        path: 'Logs/LogAction',
        name: "LogAction",
        components: { default: LogAction }
      },
      {
        path: 'Logs/LogOrder',
        name: "LogOrder",
        components: { default: LogOrder }
      },
      {
        path: 'product/ManualImport',
        name: "ManualImport",
        components: { default: ManualImport }
      },
      {
        path: 'configuration/ManageDocuments',
        name: "ManageDocuments",
        components: { default: ManageDocuments },
        beforeEnter: (to, from, next) => {
          Vue.prototype.$keycloak.hasRealmRole("00_c") ? next() : next({ name: 'Unauthorized' })
        }
      },
      {
        path: 'configuration/ObservationDocument',
        name: "ObservationDocument",
        components: { default: ObservationDocument }
      },
      {
        path: 'configuration/observationdocument/create',
        name: "ObservationDocumentCreate",
        components: { default: ObservationDocumentCreate }
      },
      {
        path: 'configuration/observationdocument/detail',
        name: "ObservationDocumentDetail",
        components: { default: ObservationDocumentDetail }
      },
      {
        path: 'configuration/FulfillmentConfiguration',
        name: "FulfillmentConfiguration",
        components: { default: FulfillmentConfiguration }
      },
      {
        path: 'configuration/FulfillmentConfiguration/Create',
        name: "FulfillmentConfigurationCreate",
        components: { default: FulfillmentConfigurationCreate }
      },
      {
        path: 'configuration/FulfillmentConfiguration/Detail',
        name: "FulfillmentConfigurationDetail",
        components: { default: FulfillmentConfigurationDetail }
      },
      {
        path: 'configuration/CustomEvent',
        name: "CustomEvent",
        components: { default: CustomEvent }
      },
      {
        path: 'configuration/CustomEvent/Create',
        name: "CustomEventCreate",
        components: { default: CustomEventCreate }
      },
      {
        path: 'configuration/CustomEvent/Detail',
        name: "CustomEventDetail",
        components: { default: CustomEventDetail }
      },
      {
        path: 'configuration/ManageSaleChannels',
        name: "ManageSaleChannels",
        components: { default: ManageSaleChannels }
      },
      {
        path: 'schedules',
        name: "Schedules",
        components: { default: Schedules },
        children: [
          {
            path: "detail",
            name: "ScheduleDetail",
            components: { default: ScheduleDetail }
          }
        ]
      },
      {
        path: 'commandbridge',
        name: "CommandBridge",
        components: { default: CommandBridge },
        children: [
          {
            path: 'sync-stock',
            name: 'SyncStock',
            components: { default: SyncStock }
          },
          {
            path: 'sync-price',
            name: 'SyncPrice',
            components: { default: SyncPrice }
          },
          {
            path: 'sync-order',
            name: 'SyncOrder',
            components: { default: SyncOrder }
          },
        ],
        beforeEnter: (to, from, next) => {
          Vue.prototype.$keycloak.hasRealmRole("04_r") ? next() : next({ name: 'Unauthorized' })
        }
      },
      {
        path: 'sync-status',
        name: 'SyncStatus',
        components: { default: SyncStatus }
      },
      {
        path: 'sync-incoming-payment',
        name: 'SyncIncomingPayment',
        components: { default: SyncIncomingPayment }
      },
      {
        path: 'configuration/CleaningLog',
        name: "CleaningLog",
        components: { default: CleaningLog },
        beforeEnter: (to, from, next) => {
          Vue.prototype.$keycloak.hasRealmRole("00_c") ? next() : next({ name: 'Unauthorized' })
        }
      },
      {
        path: 'configuration/ExternalSearch',
        name: "ExternalSearch",
        components: { default: ExternalSearch },
        beforeEnter: (to, from, next) => {
          Vue.prototype.$keycloak.hasRealmRole("00_c") ? next() : next({ name: 'Unauthorized' })
        }
      },
      {
        path: 'message',
        name: "Message",
        components: { default: Message }
      },
      {
        path: 'internal-log',
        name: "InternalLog",
        components: { default: InternalLog }
      },
      {
        path: 'CustomField',
        name: 'CustomField',
        components: { default: CustomField },
        beforeEnter: (to, from, next) => {
          Vue.prototype.$keycloak.tokenParsed.realm_access.roles.includes('08_r')
            || Vue.prototype.$keycloak.tokenParsed.realm_access.roles.includes('30_r') ? next() : next({ name: 'Unauthorized' })
        }
      },
      {
        path: 'CustomField/Detail',
        name: 'CustomFieldDetail',
        components: { default: CustomFieldDetail },
        beforeEnter: (to, from, next) => {
          Vue.prototype.$keycloak.hasRealmRole("00_c") ? next() : next({ name: 'Unauthorized' })
        }
      },
      {
        path: 'CustomField/Create',
        name: 'CustomFieldCreate',
        components: { default: CustomFieldCreate },
        beforeEnter: (to, from, next) => {
          Vue.prototype.$keycloak.hasRealmRole("00_c") ? next() : next({ name: 'Unauthorized' })
        }
      },
      {
        path: 'OrderRule/Create',
        name: 'OrderRuleCreate',
        components: { default: OrderRuleCreate }
      },
      {
        path: 'OrderRule/Detail',
        name: 'OrderRuleDetail',
        components: { default: OrderRuleDetail }
      },
      {
        path: 'Account',
        name: "Account",
        components: { default: Account }
      },
      {
        path: 'User',
        name: "User",
        components: { default: User },
        beforeEnter: (to, from, next) => {
          Vue.prototype.$keycloak.hasRealmRole("00_c") ? next() : next({ name: 'Unauthorized' })
        }
      },
      {
        path: 'User/Detail',
        name: "UserDetail",
        components: { default: UserDetail },
        beforeEnter: (to, from, next) => {
          Vue.prototype.$keycloak.hasRealmRole("00_c") ? next() : next({ name: 'Unauthorized' })
        }
      },
      {
        path: 'User/Create',
        name: "UserCreate",
        components: { default: UserCreate },
        beforeEnter: (to, from, next) => {
          Vue.prototype.$keycloak.hasRealmRole("00_c") ? next() : next({ name: 'Unauthorized' })
        }
      },
      {
        path: 'Group',
        name: "Group",
        components: { default: Group },
        beforeEnter: (to, from, next) => {
          Vue.prototype.$keycloak.hasRealmRole("00_c") ? next() : next({ name: 'Unauthorized' })
        }
      },
      {
        path: 'Group/Create',
        name: "GroupCreate",
        components: { default: GroupCreate },
        beforeEnter: (to, from, next) => {
          Vue.prototype.$keycloak.hasRealmRole("00_c") ? next() : next({ name: 'Unauthorized' })
        }
      },
      {
        path: 'Group/Detail',
        name: "GroupDetail",
        components: { default: GroupDetail },
        beforeEnter: (to, from, next) => {
          Vue.prototype.$keycloak.hasRealmRole("00_c") ? next() : next({ name: 'Unauthorized' })
        }
      },
      {
        path: 'Usage',
        name: 'Usage',
        components: { default: Usage },
      },
      {
        path: 'Usage/Create',
        name: 'UsageCreate',
        components: { default: UsageCreate }
      },
      {
        path: 'Usage/Detail',
        name: 'UsageDetail',
        components: { default: UsageDetail }
      },
      {
        path: 'product/list',
        name: 'ProductList',
        components: { default: ProductList },
        children: [
          {
            path: 'skus',
            name: 'skus',
            components: { default: Skus }
          },
          {
            path: 'skusLongTail',
            name: 'skusLongTail',
            components: { default: SkusLongTail }
          },
          {
            path: 'ExportProductModal',
            name: 'ExportProductModal',
            components: { default: ExportProductModal }
          }
        ]
      },
      {
        path: 'product/integration-logs',
        name: 'ProductIntegrationLog',
        components: { default: ProductIntegrationLog }
      },
      {
        path: 'product/register',
        name: 'ProductRegister',
        components: { default: ProductRegister }
      },
      {
        path: 'product/edit/:id',
        name: 'ProductEdit',
        components: { default: ProductEdit }
      },
      {
        path: 'product/attributes',
        name: 'Attributes',
        components: { default: Attributes }
      },
      {
        path: 'attributes/group',
        name: 'AttributeCreateGroup',
        components: { default: AttributeCreateGroup }
      },
      {
        path: 'product/attribute/create',
        name: 'AttributeCreate',
        components: { default: AttributeCreate }
      },
      {
        path: 'product/attribute/detail/:id',
        name: 'AttributeDetail',
        components: { default: AttributeDetail }
      },
      {
        path: 'product/sku/detail/:id',
        name: 'SkuDetail',
        components: { default: SkuDetail }
      },
      {
        path: 'product/file-imports',
        name: 'ProductListFileImports',
        components: { default: ListFileImports }
      },
      {
        path: 'product/file-imports/:id',
        name: 'ProductDetailFileImport',
        components: { default: DetailFileImport }
      },
      {
        path: 'Fulfillment',
        name: 'Fulfillment',
        components: { default: Fulfillment },
        children: [
          {
            path: 'Logs',
            name: 'LogsOrderFulfillment',
            components: { default: LogOrderFulfillmentModal }
          },
          {
            path: 'DocumentJson',
            name: 'DocumentFulfillmentJson',
            components: { default: DocumentFulfillmentJson }
          }
        ]
      }
      ,
      {
        path: 'Fulfillment/:id',
        name: 'FulfillmentDetail',
        components: { default: FulfillmentDetail }
      },
      {
        path: 'PushNotification',
        name: 'PushNotification',
        components: { default: PushNotification },
        beforeEnter: (to, from, next) => {
          Vue.prototype.$keycloak.hasRealmRole("00_c") ? next() : next({ name: 'Unauthorized' })
        }
      },
      {
        path: '/configuration/CostCenterConfiguration',
        name: "CostCenterConfiguration",
        components: { default: CostCenterConfiguration }
      },
      {
        path: 'configuration/CostCenterConfiguration/Create',
        name: "CostCenterConfigurationCreate",
        components: { default: CostCenterConfigurationCreate }
      },
      {
        path: 'configuration/CostCenterConfiguration/Detail',
        name: "CostCenterConfigurationDetail",
        components: { default: CostCenterConfigurationDetail }
      },
      {
        path: 'Organizations',
        name: 'Organizations',
        components: { default: Organizations },
      },
      {
        path: 'Organization/Create',
        name: 'OrganizationCreate',
        components: { default: OrganizationCreate }
      },
      {
        path: 'Organization/Detail',
        name: 'OrganizationDetail',
        components: { default: OrganizationDetail }
      },
      {
        path: 'PriceList',
        name: 'PriceList',
        components: { default: PriceLists },
      },
      {
        path: 'PriceList/Create',
        name: 'PriceListCreate',
        components: { default: PriceListCreate }
      },
      {
        path: 'PriceList/Detail',
        name: 'PriceListDetail',
        components: { default: PriceListDetail }
      },
    ],
  },
  {
    path: '/wizard',
    name: 'Wizard',
    component: Wizard
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: Onboarding
  },
  {
    path: '/404',
    alias: '*',
    name: 'NotFound',
    component: () => import('../pages/GeneralViews/NotFoundPage.vue')
  },
  {
    path: '/Unauthorized',
    name: 'Unauthorized',
    component: () => import('../pages/GeneralViews/Unauthorized.vue')
  },
];


export default routes;
