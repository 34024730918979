import actions from './actions'
import getters from './getters'
import mutations from './mutations'


const state = {
    ecommerceCurrent : {},
    erpList : [],
    platformList : []
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}
  