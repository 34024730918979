import Vue from "vue";
import constants from './constants';

Vue.prototype.$getRealm = function (currentUrl) {
  
  let url = currentUrl.split('.');
  let realm = '';

  if (url.length > 1) {
    switch (url[0]) {
      case 'ui':
        realm = url[1];
        break;
      default:
        realm = url[0];
        break;
    }
  }

  return realm;
}

Vue.prototype.$removeCharacters = function (stringToReplace) {
  return stringToReplace.replace(/[^\w\s]/gi, '');
}

Vue.prototype.$isNullOrEmpty = function (obj) {
  return obj === null || obj === undefined || obj === ""
}

Vue.prototype.$getConfigurationValueByType = function (configuration) {
  if (Vue.prototype.$isNullOrEmpty(configuration.Value))
    return null;

  switch (configuration.FieldType) {
    case constants.ConfigurationFieldType.Toggle:
      return configuration.Value === 'true' ? true : false;
    default:
      return configuration.Value.toString();
  }
}

Vue.prototype.$showError = function (message) {
  this.$notify({
    message: message,
    timeout: 10000,
    icon: "tim-icons icon-bell-55",
    horizontalAlign: "right",
    verticalAlign: "top",
    type: "danger",
  });
}

Vue.prototype.$showSuccess = function (message) {
  this.$notify({
    message: message,
    timeout: 3000,
    icon: "tim-icons icon-bell-55",
    horizontalAlign: "right",
    verticalAlign: "top",
    type: "success",
  });
}

Vue.prototype.$getAttributeType = function (value) {
  switch (value) {
    case constants.AttributeType.Product:
      return 'Produto';
    default:
      return 'Sku';
  }
}

Vue.prototype.$getAttributeValue = function (value) {
  switch (value) {
    case constants.AttributeValue.Decimal:
      return 'Decimal';
    case constants.AttributeValue.Integer:
      return 'Número Inteiro';
    case constants.AttributeValue.Text:
      return 'Texto';
    default:
      return ' --- ';
  }
}

Vue.prototype.$createListWithIndex = function (array, object) {
  let index = array.length + 1;
  object = object instanceof Object ? object : { url: object };
  array.push({ ...object, index: index });

  return array;
}

Vue.prototype.$showInfo = function (message) {
  this.$notify({
    message: message,
    timeout: 3000,
    icon: "tim-icons icon-bell-55",
    horizontalAlign: "center",
    verticalAlign: "top",
  });
}