const SET_SEARCH_CONNECTORS = (state, externalSearchs) => {
    state.externalSearchs = externalSearchs
}

const SET_LOGS = (state, logs) => {
    state.logs = logs
}

export default {
    SET_SEARCH_CONNECTORS,
    SET_LOGS
}