import Vue from "vue";

const getBahngleisStatus = async ({ commit }) => {
  return await Vue.prototype.$bahngleisWithoutLoading.get(`health`)
    .then(response => {
      commit('SET_BAHNGLEIS_STATUS', response.data.status);
    }).catch(() => {
      commit('SET_BAHNGLEIS_STATUS', 'Error');
    });
}

const getErpStatus = async ({ rootState, commit }) => {
  return await Vue.prototype.$bahngleisWithoutLoading.get(`SAP/health?ecommerceName=${rootState.ecommerce.Name}`)
    .then(response => {
      commit('SET_ERP_STATUS', response.data.status);
    }).catch(() => {
      commit('SET_ERP_STATUS', 'Error');
    });
}

const getServiceLayerStatus = async ({ rootState, commit }) => {
  return await Vue.prototype.$bahngleisWithoutLoading.get(`api/LoginERP/HealthServiceLayer?EcommerceName=${rootState.ecommerce.Name}`)
    .then(response => {
      commit('SET_SL_STATUS', response.data);
    }).catch(() => {
      commit('SET_SL_STATUS', false);
    });
}

const getServiceLayerXSStatus = async ({ rootState, commit }) => {
  return await Vue.prototype.$bahngleisWithoutLoading.get(`api/LoginERP/HealthServiceLayerXS?EcommerceName=${rootState.ecommerce.Name}`)
    .then(response => {
      commit('SET_XS_STATUS', response.data);
    }).catch(() => {
      commit('SET_XS_STATUS', false);
    });
}

export default {
  getBahngleisStatus,
  getErpStatus,
  getServiceLayerStatus,
  getServiceLayerXSStatus
}
