const SET_ECOMMERCE_CURRENT = (state, ecommerce) => {
    state.ecommerceCurrent = ecommerce;
};

const SET_ERP_LIST = (state, erpList) => {
    state.erpList = erpList;
};

export default {
    SET_ECOMMERCE_CURRENT,
    SET_ERP_LIST    
}

