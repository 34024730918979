import Vue from "vue";

const getCustomEvents = async ({ rootState, commit }, params) => {
  let query = "";
  if (params.type && params.name)
    query = `&${params.type}=${params.name}`;

  return await Vue.prototype.$bahngleis.get(`CustomEvent/GetPaginated?EcommerceName=${rootState.ecommerce.Name}&CurrentPage=${params.currentPage}&PerPage=${params.perPage}${query}`)
    .then(response => {
      commit('SET_CUSTOMEVENTS', response.data);
    });
}

const getLogCustomEvent = async ({ rootState, commit }, id) => {
  return await Vue.prototype.$bahngleis.get(`LogAction/getlogsofentity?identity=${id}&entity=CustomEvent`)
    .then(response => {
      commit('SET_LOGS', response.data);
    });
}

const createCustomEvent = ({ rootState, commit }, payload) => {
  payload.EcommerceName = rootState.ecommerce.Name;
  return Vue.prototype.$bahngleis
    .post("CustomEvent", payload)
    .then(res => { return Promise.resolve(res) })
    .catch(err => { return Promise.reject(err.response.data) });
}

const updateCustomEvent = ({ commit }, payload) => {
  return Vue.prototype.$bahngleis
    .put(`CustomEvent/${payload.Id}`, payload)
    .then(res => { return Promise.resolve(res) })
    .catch(err => { return Promise.reject(err.response.data) });
}

const removeCustomEvent = async ({ }, id) => {
  return await Vue.prototype.$bahngleis
    .delete(`CustomEvent/${id}`)
    .then(res => { return Promise.resolve(res) })
    .catch(err => { return Promise.reject(err.response.data) });
}

export default {
  getCustomEvents,
  getLogCustomEvent,
  createCustomEvent,
  updateCustomEvent,
  removeCustomEvent,
}
