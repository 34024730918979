import Vue from "vue";

const getCustomFields = async ({ rootState, commit }, payload) => {
  let params = Object.assign({ ...payload }, { ecommerceName: rootState.ecommerce.Name });

  return await Vue.prototype.$bahngleis.get(`CustomField/GetPaginated`, { params: params })
    .then(response => {
      commit('SET_CUSTOM_FIELDS', response.data.Items);
      return Promise.resolve(response);
    }).catch(error => {
      return Promise.reject(error);
    });
}

const getCustomField = async ({ }, id) => {
  return await Vue.prototype.$bahngleis.get(`CustomField/detail/${id}`)
    .then(response => {
      return response.data;
    });
}

const getLogCustomFields = async ({ rootState, commit }, id) => {
  return await Vue.prototype.$bahngleis.get(`LogAction/getlogsofentity?identity=${id}&entity=CustomField`)
    .then(response => {
      commit('SET_LOGS', response.data);
      return response;
    });
}

const getPaymentMethods = async ({ rootState, commit }) => {
  return await Vue.prototype.$bahngleis
    .get(`PaymentMethod/GetByEcommerce/`, {
      params: {
        ecommerceName: rootState.ecommerce.Name
      }
    })
    .then(response => {
      commit('SET_PAYMENT_METHODS', response.data.map(a => {
        return {
          name: a.Name,
          value: a.Name
        }
      }));
      return response;
    });
}

const getWarehouses = async ({ rootState, commit }) => {
  let params = Object.assign(
    {
      ecommerceName: rootState.ecommerce.Name,
      active: true,
      isIntegration: true
    }
  );

  return await Vue.prototype.$bahngleis
    .get("configuration/warehouse", { params: params })
    .then(response => {
      commit('SET_WAREHOUSES', response.data.map(a => {
        return {
          Label: a.Code,
          Key: a.Code
        }
      }));
      commit('SET_BRANCHS', response.data.map(a => {
        return {
          Label: a.Filial,
          Key: a.Filial
        }
      }));
      return response;
    });
}

const getChannels = async ({ rootState, commit }) => {
  let params = Object.assign({ ecommerceName: rootState.ecommerce.Name });
  return await Vue.prototype.$bahngleis
    .get("Channels/GetByEcommerce", { params: params })
    .then(response => {
      commit('SET_CHANNELS', response.data.map(a => {
        return {
          Label: a.EcommerceChannel,
          Key: a.EcommerceChannel
        }
      }));
      return response;
    });
}

const getShippingMethods = async ({ rootState, commit }) => {
  return await Vue.prototype.$bahngleis
    .get(`ShippingMethod/GetByEcommerce/`, {
      params: {
        ecommerceName: rootState.ecommerce.Name
      }
    })
    .then(response => {
      commit('SET_SHIPPING_METHODS', response.data.map(a => {
        return {
          name: a.Name,
          value: a.Name
        }
      }));
      return response;
    });
}

const createCustomField = async ({ commit }, payload) => {
  return await Vue.prototype.$bahngleis.post(`CustomField`, payload);
}

const updateCustomField = async ({ commit }, payload) => {
  return await Vue.prototype.$bahngleis.put(`CustomField`, payload);
}

const removeCustomField = async ({ commit }, id) => {
  return await Vue.prototype.$bahngleis.delete(`CustomField/${id}`);
}

export default {
  getCustomFields,
  createCustomField,
  updateCustomField,
  removeCustomField,
  getCustomField,
  getLogCustomFields,
  getPaymentMethods,
  getShippingMethods,
  getWarehouses,
  getChannels
}
