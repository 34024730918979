import Vue from "vue";

const getRecurringJobHistories = ({ rootState }) => {
  return Vue.prototype.$bahngleis
    .get(`RecurringJobs/histories`, {
      params: {
        ecommerceName: rootState.ecommerce.Name,
      }
    });
}

const getSyncsPending = ({ rootState }) => {
  return Vue.prototype.$bahngleis
    .get(`product/GetSyncsPending`, {
      params: {
        ecommerceName: rootState.ecommerce.Name,
      }
    });
}

const getLogCustomEvent = async ({ rootState, commit }, entities) => {
  const params = new URLSearchParams();

  for (const entitie of entities) {
    params.append('entities', entitie);
  }

  params.append('identity', rootState.ecommerce.Name);

  return await Vue.prototype.$bahngleis.get(`LogAction/getlogsofentities`, {
    params: params
  }).then(response => {
    commit('SET_LOGS', response.data);
  });
}

export default {
  getRecurringJobHistories,
  getSyncsPending,
  getLogCustomEvent
}
