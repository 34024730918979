const getConfigurationGroup = (arr) => {
  return Array.from(new Set(arr.map((s) => s.Group))).map(
      (id) => {
      return {
          Id: id,
          GroupType: arr.find((a) => a.Group === id)
          .Group,
          GroupDescription: arr.find((a) => a.Group === id).GroupDescription
      };
      }
  );
};

const SET_CONFIGURATIONS = (state, configurations) => {
  state.configurations = configurations;
  state.configurationGroup = getConfigurationGroup(configurations);
  state.configurationGroup.sort((a, b) => {
    if (a.GroupType < b.GroupType) {
      return -1;
    }
    if (a.GroupType > b.GroupType) {
      return 1;
    }
    return 0;
  });
}

const SET_RECURRING_JOBS = (state, recurringJobs) => {
  recurringJobs.forEach(r => {
    r.ActiveOrigem = r.Active;
  });

  state.recurringJobs = recurringJobs;
}

const SET_CONFIGURATIONS_SAP = (state, configurations) => {
  if (configurations.BusinessPartnerSeries)
    configurations.BusinessPartnerSeries.forEach((item) => {
      if (item.Prefix === undefined) item.Prefix = "Vazio";
    });

  state.businessPartnerGroups = configurations.BusinessPartnerGroups;
  state.businessPartnerSeries = configurations.BusinessPartnerSeries;
  state.businessPlaces = configurations.BusinessPlaces;
  state.priceLists = configurations.PriceLists;
  state.salesPersons = configurations.SalesPersons;
  state.shippingCodes = configurations.ShippingCodes;
}

const SET_SCHEDULE_CURRENT = (state, schedule) => {
  state.scheduleCurrent = schedule;
}

export default {
  SET_CONFIGURATIONS,
  SET_RECURRING_JOBS,
  SET_CONFIGURATIONS_SAP,
  SET_SCHEDULE_CURRENT
}
