const SET_NOTIFICATION_EMAILS = (state, notificationEmails) => {
    state.notificationEmails = notificationEmails
}

const SET_NOTIFICATION_EMAIL_CURRENT = (state, notificationEmail) => {
    state.notificationEmailCurrent = notificationEmail;
}

const SET_NOTIFICATION_EMAIL_LOGS = (state, notificationEmailLogs) => {
    state.notificationEmailLogs = notificationEmailLogs
}

const SET_LOGS = (state, logs) => {
    state.logs = logs
}

export default {
    SET_NOTIFICATION_EMAILS,
    SET_NOTIFICATION_EMAIL_CURRENT,
    SET_NOTIFICATION_EMAIL_LOGS,
    SET_LOGS
}