import { mapState } from 'vuex';
import constants from "../util/constants";

export default {
    data: function () {
        return {
            isPlanControlActive: true,
            plan: [],
            planControl: [],
            functionalities: []
        }
    },
    computed : {
        ...mapState(['ecommerce'])
    },
    methods: {
        async getPlan() {
            await this.$bahngleis.get(
                `Configuration/GetByKeyWithEcommerce?key=PlanControl&ecommerceName=${this.ecommerce.Name}`
            ).then(response => {
                this.planControl = response.data;
            });

            localStorage.setItem("bahn-plan-control", JSON.stringify(this.planControl));

            if(this.ecommerce && this.planControl)
                this.plan = this.planControl.find(
                    (f) => f.EcommerceName === this.ecommerce.Name
                );

            this.isPlanControlActive = (this.plan && this.plan.Value == "true") || false;;

            return this.plan;
        },
        async getFunctionalities(isPlanControlActive) {
            if (!isPlanControlActive) {
                console.error("Validação de planos está inativa");
                return;
            }

            let existingFunctionalities = localStorage.getItem("bahn-plan-functionalities");
            this.functionalities = JSON.parse(existingFunctionalities) || [];

            if (!this.functionalities.length) {
                //TODO: Esconder a senha
                let tokenResponse = await this.$nordware_config.post(
                    "/api/v1/Client/Login",
                    {
                        userName: "Bahn",
                        password: "mkmk@123",
                    }
                );

                let auth = `Bearer ${tokenResponse.data.token}`;
                let responseFunctionalities = await this.$nordware_config.get(
                    `/api/v1/Functionalities?ClientName=${this.$store.state.realm}&applicationName=Bahn`,
                    {
                        headers: {
                            Authorization: auth,
                        },
                    }
                );

                this.functionalities = responseFunctionalities.data;

                localStorage.setItem("bahn-plan-functionalities", JSON.stringify(responseFunctionalities.data));
            }

            return this.functionalities;
        },
        hasPermission(functionalitie){
            if (!this.isPlanControlActive) return;

            this.functionalities.some(
                (f) => f.identifier === functionalitie
            );
        }
    }
}
