import actions from "./actions";
import mutations from './mutations';

const state = {
    usages: [],
    logs: [],
    usageCurrent : {}
}

export default {
    namespaced : true,
    state,
    actions,
    mutations
}