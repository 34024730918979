const SET_PAYMENTMETHODS = (state, paymentMethods) => {
  state.paymentMethods = paymentMethods
}

const SET_LOGS = (state, logs) => {
  state.logs = logs
}

const SET_PAYMENTMETHOD_CURRENT = (state, paymentMethod) => {
  state.paymentMethodCurrent = paymentMethod;
}

const SET_CREDIT_CARD_CODES = (state, creditCardCodes) => {
  state.creditCardCodes = creditCardCodes;
}

const SET_PAYMENT_METHOD_TYPES = (state, paymentMethodTypes) => {
  state.paymentMethodTypes = paymentMethodTypes;
}

const SET_PAYMENT_METHOD_CODES = (state, paymentMethodCodes) => {
  state.paymentMethodCodes = paymentMethodCodes;
}

const SET_PAYMENT_CONDITIONS = (state, paymentConditions) => {
  state.paymentConditions = paymentConditions;
}

const SET_ERP_PAYMENT_METHODS = (state, erpPaymentMethods) => {
  state.erpPaymentMethods = erpPaymentMethods;
}

export default {
  SET_PAYMENTMETHODS,
  SET_LOGS,
  SET_PAYMENTMETHOD_CURRENT,
  SET_CREDIT_CARD_CODES,
  SET_PAYMENT_METHOD_TYPES,
  SET_PAYMENT_METHOD_CODES,
  SET_PAYMENT_CONDITIONS,
  SET_ERP_PAYMENT_METHODS
}
