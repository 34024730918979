import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
    productsMapping : [],
    productMappingCurrent : {},
    products: [],
    operation: null,
    productCurrent: {},
    attributes : [],
    attributeCurrent : {},
    productIntegrationLog : [],
    filesLog : [],
    fileLogCurrent : [],
    scheduleSyncProductsActive : false,
    logs: [],
    formChanged: false,
    productFilter: null
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
