import Vue from "vue";
import moment from "moment";


const getStatus = (status) => {
    switch (status) {
        case 0:
            return { description : "Sucesso" , class : "status statusComplete" }
        case 1:
            return { description : "Erro" , class : "status statusError" }
        case 2:
            return { description : "Iniciado" , class : "status statusProcessing" }
    }
  };

const getImage = (skus) => {
    let sku = skus.find(a => a.Active && a.Imagens.length > 0 && a.Imagens.some(x => x.Type === 1 || x.Type === 2));

    if (sku == null)
        return '';

    let image = sku.Imagens.find(i => i.Type === 1 || i.Type === 2);
    return image ? image.Url : '';
}

const isDefault = (name) => {
    switch (name) {
        case "Weight":
        case "Height":
        case "Length":
        case "Width":
        case "Unit":
            return true;
        default:
            return false;
    }
  };

  const getType = (type) => {
    switch (type) {
        case 1:
            return { description : "Novo Cadastro" , class : "status statusComplete" }
        case 2:
            return { description : "Atualização" , class : "status statusScheduled" }
    }
  };

const productIntegrationLog = state => {
    return state.productIntegrationLog.map(a => {
        return {
            Id : a.Id,
            Status : getStatus(a.Status),
            Type : getType(a.Type),
            ProductId : a.ProductId,
            ProductName : a.ProductName,
            ProcessedDate : moment.utc(String(a.ProcessedDate)).format('DD/MM/YYYY'),
            Message : a.Message,
            StatusDescription: a.StatusDescription,
            TypeDescription: a.TypeDescription
        }
    });
}

const productsToTable = state => {
    let products = state.products.map(a => {
        return {
            id : a.Id,
            name : a.Name,
            type : a.Variation ? 'Com Variação' : 'Simples',
            idEcommerce : a.ECommerceId,
            status : a.Active ? 'Ativo' : 'Inativo',
            image : a.Skus && a.Skus.length > 0 && a.Skus.some(a => a.Imagens.length > 0) ? getImage(a.Skus) : "",
            skus : a.Skus
        };
    });

    return products;
}

const attributesToTable = state => {
    return state.attributes.sort((a, b) => a.Id - b.Id).map(a => {
        return {
            id : a.Id,
            name : a.Name,
            description : a.Description,
            active : a.Active ? 'Sim' : 'Não',
            specificationType : Vue.prototype.$getAttributeType(a.SpecificationType),
            valueType : Vue.prototype.$getAttributeValue(a.ValueType),
            default : isDefault(a.Name),
            group : Vue.prototype.$isNullOrEmpty(a.AttributeGroupName) ? '---' : a.AttributeGroupName
        }
    })
}

export default {
    productsToTable,
    attributesToTable,
    productIntegrationLog
}