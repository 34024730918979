const SET_FULFILLMENTCONFIGURATIONS = (state, fulfillmentConfigurations) => {
    state.fulfillmentConfigurations = fulfillmentConfigurations
}

const SET_LOGS = (state, logs) => {
    state.logs = logs
}

const SET_FULFILLMENTCONFIGURATION_CURRENT = (state, fulfillmentConfiguration) => {
    state.fulfillmentConfigurationCurrent = fulfillmentConfiguration;
}

const SET_FULFILLMENTS = (state, model) => {
    state.fulfillments = model;
}

const SET_FULFILLMENT = (state, model) => {
    state.fulfillmentCurrent = model;
}

export default {
    SET_FULFILLMENTCONFIGURATIONS,
    SET_LOGS,
    SET_FULFILLMENTCONFIGURATION_CURRENT,
    SET_FULFILLMENTS,
    SET_FULFILLMENT
}
