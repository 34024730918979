import Vue from "vue";

const getCostCenterConfigurations = async ({ rootState, commit }, params) => {
  let query = "";
  if (params.type && params.name)
    query = `&${params.type}=${params.name}`;

  return await Vue.prototype.$bahngleis.get(`CostCenterConfiguration/GetPaginated?EcommerceName=${rootState.ecommerce.Name}&CurrentPage=${params.currentPage}&PerPage=${params.perPage}${query}`)
    .then(response => {
      commit('SET_COSTCENTERCONFIGURATIONS', response.data);
      return response;
    });
}

const getLogCostCenterConfiguration = async ({ rootState, commit }, id) => {
  return await Vue.prototype.$bahngleis.get(`LogAction/getlogsofentity?identity=${id}&entity=CostCenterConfiguration`)
    .then(response => {
      commit('SET_LOGS', response.data);
      return response;
    });
}

const createCostCenterConfiguration = ({ rootState, commit }, payload) => {  
  payload.EcommerceName = rootState.ecommerce.Name;
  return Vue.prototype.$bahngleis
    .post("CostCenterConfiguration", payload)
    .then(res => { return Promise.resolve(res) })
    .catch(err => { return Promise.reject(err.response.data) });
}

const updateCostCenterConfiguration = ({ commit }, payload) => {
  return Vue.prototype.$bahngleis
    .put(`CostCenterConfiguration/${payload.Id}`, payload)
    .then(res => { return Promise.resolve(res) })
    .catch(err => { return Promise.reject(err.response.data) });
}

const removeCostCenterConfiguration = async ({ }, id) => {
  return await Vue.prototype.$bahngleis
    .delete(`CostCenterConfiguration/${id}`)
    .then(res => { return Promise.resolve(res) })
    .catch(err => { return Promise.reject(err.response.data) });
}

export default {
  getCostCenterConfigurations,
  getLogCostCenterConfiguration,
  createCostCenterConfiguration,
  updateCostCenterConfiguration,
  removeCostCenterConfiguration
}
