const attributeTypes = [
    { value: 1, key: "Product" },
    { value: 2, key: "Sku" },
];

const attributeValues = [
    { value: 1, key: "Text" },
    { value: 2, key: "Integer" },
    { value: 3, key: "Decimal" },
];


const SET_PRODUCTS_MAPPING = (state, data) => {
    state.productsMapping = data;
}

const SET_PRODUCT_FILTER = (state, filter) => {
    state.productFilter = filter;
}

const SET_PRODUCTS = (state, model) => {
    state.products = model;
}

const SET_PRODUCT_CURRENT = (state, model) => {
    state.productCurrent = model;
}

const SET_ATTRIBUTES = (state, model) => {
    state.attributes = model;
}

const SET_ATTRIBUTE_CURRENT = (state, model) => {
    state.attributeCurrent = model;
    state.attributeCurrent.SpecificationType = attributeTypes.find(a => a.value === model.SpecificationType).key;
    state.attributeCurrent.ValueType = attributeValues.find(a => a.value === model.ValueType).key;
}

const SET_PRODUCT_INTEGRATION_LOG = (state, model) => {
    state.productIntegrationLog = model;
}

const SET_FILE_LOG = (state, model) => {
    state.fileLogCurrent = model;
}

const SET_FILES_LOG = (state, model) => {
    state.filesLog = model;
}

const SET_SCHEDULE_SYNC_PRODUCTS_ACTIVE = (state, model) => {
    state.scheduleSyncProductsActive = model;
}

const SET_LOGS = (state, logs) => {
  state.logs = logs
}

const SET_FORM_CHANGED = (state, payload) => {
    state.formChanged = payload;
}

export default {
    SET_PRODUCTS_MAPPING,
    SET_PRODUCTS,
    SET_PRODUCT_CURRENT,
    SET_ATTRIBUTES,
    SET_ATTRIBUTE_CURRENT,
    SET_PRODUCT_INTEGRATION_LOG,
    SET_FILE_LOG,
    SET_FILES_LOG,
    SET_SCHEDULE_SYNC_PRODUCTS_ACTIVE,
    SET_LOGS,
    SET_FORM_CHANGED,
    SET_PRODUCT_FILTER
}
