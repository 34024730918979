const organizationsToTable = state => {
  const organizations = state.organizations.sort((a, b) => a.Id - b.Id).map(organization => {
    return {
      id: organization.Id,
      name: organization.Name,
      salesOrganization: organization.SalesOrganization,
      distributionChannel: organization.DistributionChannel,
      division: organization.Division
    };
  });

  return organizations;
};

const organizationsToSelectField = state => {
  const organizations = state.organizations.sort((a, b) => a.Id - b.Id).map(organization => {
    return {
      Label: organization.Name,
      Value: organization.Id
    };
  });

  return organizations;
}

export default {
  organizationsToTable,
  organizationsToSelectField
}
