const paymentMethodsToTable = state => {
  const paymentMethods = state.paymentMethods.sort((a, b) => a.Id - b.Id).map(payment => {
    return {
      account: payment.Account ? payment.Account : 'Não especificado',
      channel: payment.Channel ? payment.Channel : 'Não especificado',
      creditCardCode: payment.CreditCardCode ? payment.CreditCardCode : 'Não especificado',
      ecommerce: payment.Ecommerce ? payment.Ecommerce : 'Não especificado',
      ecommerceReference: payment.EcommerceReference ? payment.EcommerceReference : 'Não especificado',
      erpReference: payment.ErpReference ? payment.ErpReference : 'Não especificado',
      hubReference: payment.HubReference ? payment.HubReference : 'Não especificado',
      id: payment.Id,
      installments: payment.Installments ? payment.Installments : 1,
      isSplitPayments: payment.IsSplitPayments ? 'Aceita dividir parcelas' : 'Não aceita dividir parcelas',
      name: payment.Name,
      paymentCondition: payment.PaymentCondition ? payment.PaymentCondition : 'Não especificado',
      paymentMethodCode: payment.PaymentMethodCode ? payment.PaymentMethodCode : 'Não especificado',
      paymentMethodType: payment.PaymentMethodType ? payment.PaymentMethodType : 'Não especificado',
    };
  });

  return paymentMethods;
};

export default {
  paymentMethodsToTable
}
