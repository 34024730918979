import actions from "./actions";
import mutations from './mutations';

const state = {
    bahnhof: {},
    bahngleis: {},
    displayRestrictedData: false,
    serviceLayer: false,
    serviceLayerXS: false
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}