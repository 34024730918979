const SET_CLEANINGLOGS = (state, cleaningLogs) => {
    state.cleaningLogs = cleaningLogs
}

const SET_LOGS = (state, logs) => {
    state.logs = logs
}

export default {
    SET_CLEANINGLOGS,
    SET_LOGS
}