<template>
  <!-- <base-dropdown
    tag="li"
    title-tag="a"
    class="nav-item"
    title-classes="nav-link"
    menu-classes="dropdown-navbar"
  >
    <template slot="title">
      <i class="el-icon-user"></i>
      </template>
<li class="nav-link">
  <div>
    <b class="d-lg-block d-xl-block">{{profile.firstName}}</b>
  </div>
</li>
<div class="dropdown-divider"></div>
<li class="nav-link">
  <a @click="addNew" class="nav-item dropdown-item" target="_blank">
    <i class="material-icons">account_circle</i> Minha Conta
  </a>
</li>
<div class="dropdown-divider"></div>

<li class="nav-link">
  <a href="https://ajuda.nordware.io/hc/pt-br/categories/7492299968151-Bahn" class="nav-item dropdown-item"
    target="_blank">
    <i class="material-icons">help</i> Ajuda
  </a>
</li>
<div class="dropdown-divider"></div>
<li class="nav-link">
  <a v-bind:href=UrlLogout class="nav-item dropdown-item">
    <i class="material-icons">logout</i> Sair
  </a>
</li>
</base-dropdown> -->
  <el-dropdown @command="handleCommand">
    <span class="el-dropdown-link">
      <i class="el-icon-user" style="font-size:20px; color: #495472 !important"></i>
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="Minha Conta">Minha Conta</el-dropdown-item>
      <el-dropdown-item command="Ajuda">Ajuda</el-dropdown-item>
      <el-dropdown-item command="Sair">Sair</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapState } from 'vuex';
import Vue from "vue";

export default {
  name: "UserMenu",
  data() {
    return {
      profile: {},
      info: {}
    };
  },
  beforeMount() {
    this.$keycloak.loadUserProfile().success(profile => {
      this.profile = profile;
    });
    this.$keycloak.loadUserInfo().success(info => {
      this.info = info;
      this.setUserNameIntoStorage(info.preferred_username);
    });
  },
  computed: {
    ...mapState(['realm'])
  },
  methods: {
    toHelp() {
      window.open("https://ajuda.nordware.io/hc/pt-br/categories/7492299968151-Bahn", "_blank");
    },
    setUserNameIntoStorage(userName) {
      localStorage["profile_username"] = userName;
    },
    addNew() {
      this.$router.push({ name: "Account" })
    },
    handleCommand(command) {
      switch (command) {
        case 'Minha Conta':
          this.$router.push({ name: "Account" });
          break;
        case 'Ajuda':
          window.open("https://ajuda.nordware.io/hc/pt-br/categories/7492299968151-Bahn", "_blank");
          break;
        case 'Sair':
          Vue.prototype.$keycloak_api.post(this.$store.state.realm + '/users/' + this.info.sub + '/logout');
          window.location.href = 'http://' + location.host + '/Dashboard';
          break;
      }
    }
  },
};
</script>

<style>
.el-dropdown {
  color: none !important;
}
</style>
