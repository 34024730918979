import Vue from "vue";

const getUsers = async ({ rootState, commit }, params) => {
    let query = "";
    if (params.name)
        query = `&search=${params.name}`;

    return await Vue.prototype.$keycloak_api.get(params.realm + `/users?briefRepresentation=true&first=` + params.first + `&max=` + params.perPage + query)
        .then(response => {
            commit('SET_USERS', response.data);
        });
}

const getUsersCount = async ({ commit }, params) => {
    let query = "";
    if (params.type && params.name)
        query = `?search=${params.name}`;
    return await Vue.prototype.$keycloak_api.get(params.realm + `/users/count` + query)
        .then(response => {
            commit('SET_USERS_COUNT', response.data);
        });
}

const getUserGroups = async ({ commit }, params) => {
    return await Vue.prototype.$keycloak_api.get(params.realm + `/users/` + params.id + `/groups/`)
        .then(response => {
            commit('SET_USER_GROUPS', response.data);
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        });
}

const createUser = async ({ rootState, commit }, payload) => {
    return await Vue.prototype.$bahngleis.post("Keycloak/User", payload.formData)
        .then(res => { return Promise.resolve(res) })
        .catch(err => { return Promise.reject(err.response.data) });;
}

const updateUser = async ({ rootState, commit }, payload) => {
    return await Vue.prototype.$bahngleis.put("Keycloak/User", payload.formData)
        .then(res => { return Promise.resolve(res) })
        .catch(err => { return Promise.reject(err.response.data) });
}

const removeUser = async ({ commit }, payload) => {
    return await Vue.prototype.$bahngleis.delete(`Keycloak/User/${payload.id}`);
}

const getUserLogs = async ({ rootState, commit }, id) => {
    return await Vue.prototype.$bahngleis.get(`LogAction/getlogsofentity?identity=${id}&entity=User`)
        .then(response => {
            commit('SET_LOGS', response.data);
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        });
}

const resetPassword = async ({ commit }, payload) => {
    return await Vue.prototype.$bahngleis.put(`Keycloak/User/ResetPassword`, payload.formData)
        .then(res => { return Promise.resolve(res) })
        .catch(err => { return Promise.reject(err.response.data) });
}

export default {
    getUsers,
    getUsersCount,
    createUser,
    updateUser,
    removeUser,
    getUserGroups,
    getUserLogs,
    resetPassword
}
