<template>
  <h4>
    <slot>
      <base-button
        class="like !important btn-link"
        type="primary"
        size="sm"
        icon
        @click="routeName ? $router.push({ name: routeName }) : $router.go(-1)"
      >
        <i class="material-icons">arrow_back</i>
      </base-button>

      {{ title }} 
    </slot>
  </h4>
</template>

<script>
export default {
  name: "arrow-back",
  props: {
    title: {
      type: String,
      description: "Page title",
    },
    routeName: {
      type: String,
      description: "URL name to return",
    },
  },
};
</script>

<style>
</style>