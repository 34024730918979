import { EventBus } from "@/eventBus";

export default {
    data() {
        return {
            steps: [
                {
                    target: '[data-v-step="0"]',
                    content: `Olá sou o seu assistente virtual de configuração e irei te ajudar a configurar o <strong>Bahn</strong> vamos lá!`,
                    before: (type) =>
                        new Promise((resolve, reject) => {
                            if (type === "start") this.$router.push({ name: "GeralConfigurations" });
                            resolve("foo");
                        }),
                },
                {
                    target: "#v-step-1",
                    content: `Informe o <b>Id da Filial</b>`,
                    before: (type) =>
                        new Promise((resolve, reject) => {
                            EventBus.$emit("changeTab", "Pedido");
                            resolve("foo");
                        }),
                    params: {
                        placement: "left",
                    },
                },
                {
                    target: "#v-step-2",
                    content: `Informe a <b>Integração do Preço no Pedido</b>`,
                    before: (type) =>
                        new Promise((resolve, reject) => {
                            EventBus.$emit("changeTab", "Preço");
                            resolve("foo");
                        }),
                    params: {
                        placement: "left",
                    },
                },
                {
                    target: "#v-step-3",
                    content: `Informe a <b>Lista de Preço</b>`,
                    params: {
                        placement: "left",
                    },
                },
                {
                    target: "#v-step-4",
                    content: `Deseja habilitar ? `,
                    before: (type) =>
                        new Promise((resolve, reject) => {
                            EventBus.$emit("changeTab", "Preço");
                            resolve("foo");
                        }),
                    params: {
                        placement: "left",
                    },
                },
                {
                    target: "#v-step-5",
                    content: `Informe o <b>Estoque de segurança inteligente</b>`,
                    before: (type) =>
                        new Promise((resolve, reject) => {
                            EventBus.$emit("changeTab", "Estoque");
                            resolve("foo");
                        }),
                    params: {
                        placement: "left",
                    },
                },
                {
                    target: "#v-step-6",
                    content: `Informe o <b>Estoque de segurança</b>`,
                    params: {
                        placement: "left",
                    },
                },
                {
                    target: "#v-step-7",
                    content: `Deseja <b>Atualizar estoque ao integrar pedido</b> ?`,
                    params: {
                        placement: "left",
                    },
                },
                {
                    target: "#v-step-8",
                    content: `Deseja <b>Habilitar múltiplos depósitos</b>`,
                    before: (type) =>
                        new Promise((resolve, reject) => {
                            EventBus.$emit("changeTab", "Estoque");
                            resolve("foo");
                        }),
                    params: {
                        placement: "left",
                    },
                },
                {
                    target: "#v-step-9",
                    content: `Informe o <b>GroupCode</b>`,
                    before: (type) =>
                        new Promise((resolve, reject) => {
                            EventBus.$emit("changeTab", "Parceiro");
                            resolve("foo");
                        }),
                    params: {
                        placement: "left",
                    },
                },
                {
                    target: "#v-step-10",
                    content: `Informe o <b>Nome do Vendedor</b>`,
                    params: {
                        placement: "left",
                    },
                },
                {
                    target: "#v-step-11",
                    content: `Informe a <b>Sequência</b>`,
                    before: (type) =>
                        new Promise((resolve, reject) => {
                            EventBus.$emit("changeTab", "Parceiro");
                            resolve("foo");
                        }),
                    params: {
                        placement: "left",
                    },
                },
                {
                    target: "#v-step-12",
                    content: `Informe o <b>Idioma</b>`,
                    before: (type) =>
                        new Promise((resolve, reject) => {
                            EventBus.$emit("changeTab", "Geral");
                            resolve("foo");
                        }),
                    params: {
                        placement: "left",
                    },
                },
                {
                    target: "#v-step-13",
                    content: `Informe a <b>Moeda</b>`,
                    params: {
                        placement: "left",
                    },
                },
                {
                    target: "#v-step-14",
                    content: `Informe a <b>Origem</b>`,
                    params: {
                        placement: "left",
                    },
                },
                {
                    target: "#v-step-15",
                    content: `Deseja <b>Integrar com ERP</b>`,
                    before: (type) =>
                        new Promise((resolve, reject) => {
                            if (this.$route.name !== "GeralConfigurations")
                                this.$router.push({ name: "GeralConfigurations" });

                            EventBus.$emit("changeTab", "Geral");
                            resolve("foo");
                        }),
                    params: {
                        placement: "left",
                    },
                },
                {
                    target: "#v-step-16",
                    content: `Adicione as credenciais para se conectar com o ERP`,
                    before: (type) =>
                        new Promise((resolve, reject) => {
                            if (this.$route.name !== "Configurations")
                                this.$router.push({ name: "Configurations" });

                            setTimeout(() => {
                                resolve("foo");
                            }, 1000);
                        }),
                    params: {
                        placement: "left",
                    },
                },
                {
                    target: "#v-step-17",
                    content: `Adicione as credenciais para se conectar com o Ecommerce`,
                    before: (type) =>
                        new Promise((resolve, reject) => {
                            if (this.$route.name !== "Configurations")
                                this.$router.push({ name: "Configurations" });
                            resolve("foo");
                        }),
                    params: {
                        placement: "left",
                    },
                },
                {
                    target: "#v-step-18",
                    content: `Selecione o Addon que deseja utilizar`,
                    before: (type) =>
                        new Promise((resolve, reject) => {
                            if (this.$route.name !== "Addon")
                                this.$router.push({ name: "Addon" });

                            setTimeout(() => {
                                resolve("foo");
                            }, 1000);
                        }),
                    params: {
                        placement: "bottom",
                    },
                },
                {
                    target: "#v-step-19",
                    content: `Configure os Depósitos`,
                    before: (type) =>
                        new Promise((resolve, reject) => {
                            if (this.$route.name !== "WarehouseConfiguration")
                                this.$router.push({ name: "WarehouseConfiguration" });

                            setTimeout(() => {
                                resolve("foo");
                            }, 1000);
                        }),
                    params: {
                        placement: "bottom",
                    },
                },
                {
                    target: "#v-step-20",
                    content: `Configure os Métodos de envio`,
                    before: (type) =>
                        new Promise((resolve, reject) => {
                            if (this.$route.name !== "ShippingMethods")
                                this.$router.push({ name: "ShippingMethods" });

                            setTimeout(() => {
                                resolve("foo");
                            }, 1000);

                        }),
                    params: {
                        placement: "bottom",
                    },
                },
                {
                    target: "#v-step-21",
                    content: `Configure as Formas de Pagamento`,
                    before: (type) =>
                        new Promise((resolve, reject) => {
                            if (this.$route.name !== "PaymentMethods")
                                this.$router.push({ name: "PaymentMethods" });

                            setTimeout(() => {
                                resolve("foo");
                            }, 1000);
                        }),
                    params: {
                        placement: "bottom",
                    },
                },
                {
                    target: "#v-step-22",
                    content: `Habilite quais Documentos deseja gerar`,
                    before: (type) =>
                        new Promise((resolve, reject) => {
                            if (this.$route.name !== "ManageDocuments")
                                this.$router.push({ name: "ManageDocuments" });

                            setTimeout(() => {
                                resolve("foo");
                            }, 1000);
                        }),
                    params: {
                        placement: "left",
                    },
                },
                {
                    target: "#v-step-23",
                    content: `Configure os agendamentos`,
                    before: (type) =>
                        new Promise((resolve, reject) => {
                            if (this.$route.name !== "Schedules")
                                this.$router.push({ name: "Schedules" });
                           
                            setTimeout(() => {
                                resolve("foo");
                            }, 1000);
                        }),
                    params: {
                        placement: "left",
                    },
                },
                {
                    target: "#v-step-24",
                    content: `Verifique os Status`,
                    before: (type) =>
                        new Promise((resolve, reject) => {
                            if (this.$route.name !== "Status") 
                                this.$router.push({ name: "Status" });
                            
                            setTimeout(() => {
                                resolve("foo");
                            }, 1000);
                        }),
                    params: {
                        placement: "left",
                    },
                }
            ]
        }
    },
}