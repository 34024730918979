import Vue from "vue";

const getNotificationEmails = async ({ rootState, commit }, params) => {
  let query = "";

  if (params.type && params.name)
    query = `&${params.type}=${params.name}`;

  return await Vue.prototype.$bahngleis.get(`NotificationEmail/GetByEcommerce?ecommerceName=${rootState.ecommerce.Name}&currentPage=${params.currentPage}&perPage=${params.perPage}`)
    .then(response => {
      commit('SET_NOTIFICATION_EMAILS', response.data);
    });
}

const createNotificationEmail = async ({ rootState, commit }, payload) => {
  try {
    payload.EcommerceName = rootState.ecommerce.Name;
    return await Vue.prototype.$bahngleis.post("NotificationEmail", payload);
  } catch (error) {
    if (error.response && error.response.data && error.response.data === "A configuration with the same notification email type already exists.") {
      throw new Error("Já existe uma notificação de e-mail com o mesmo tipo. Por favor, escolha outro tipo de notificação.");
    }
  }
}

const updateNotificationEmail = async ({ commit }, payload) => {
  return await Vue.prototype.$bahngleis.put(`NotificationEmail/${payload.Id}`, payload);
}

const removeNotificationEmail = async ({ }, id) => {
  return await Vue.prototype.$bahngleis.delete(`NotificationEmail/${id}`);
}

const getLogNotificationEmail = async ({ rootState, commit }, id) => {
  return await Vue.prototype.$bahngleis.get(`LogAction/getlogsofentity?identity=${id}&entity=NotificationEmail`)
    .then(response => {
      commit('SET_LOGS', response.data);
    });
}

const getNotificationEmailLogs = async ({ rootState, commit }, params) => {

  return await Vue.prototype.$bahngleis.get(`NotificationEmailLog/getbynotificationemail?ecommerceName=${rootState.ecommerce.Name}&notificationEmailId=${params.notificationEmailId}&currentPage=${params.currentPage}&perPage=${params.perPage}`)
    .then(response => {
      commit('SET_NOTIFICATION_EMAIL_LOGS', response.data);
    });
}

const getNotificationEmailLogEcommerce = async ({ rootState, commit }, params) => {
  let query = "";

  if (params.from)
    query = `&startDate=${params.from}`;

  if (params.to)
    query += `&endDate=${params.to}`;

  return await Vue.prototype.$bahngleis.get(`NotificationEmailLog/GetAll?ecommerceName=${rootState.ecommerce.Name}&currentPage=${params.currentPage}&perPage=${params.perPage}${query}`)
    .then(response => {
      commit('SET_NOTIFICATION_EMAIL_LOGS', response.data);
    });
}

export default {
  getNotificationEmails,
  createNotificationEmail,
  updateNotificationEmail,
  removeNotificationEmail,
  getNotificationEmailLogs,
  getNotificationEmailLogEcommerce,
  getLogNotificationEmail
}
