import Vue from "vue";

const getLogActionPaginated = async ({ rootState, commit }, params) => {
  let query = "";
  if (params.orderNumber)
    query = `&orderNumber=${params.orderNumber}`;

  return await Vue.prototype.$bahngleis.get(`LogAction/GetPaginated?CurrentPage=${params.currentPage}&PerPage=${params.perPage}&Identity=${params.identity}&entity=${params.entity}${query}`)
    .then(response => {
      commit('SET_LOG_ORDERS', response.data);
      return Promise.resolve(response);
    }).catch(error => {
      return Promise.reject(error);
    });
}

const getLogStock = async ({ }, params) => {
  return await Vue.prototype.$bahngleis.get(`api/LogStock/GetPaginated`, {
    params: params
  });
}

const exportLogStock = async ({ rootState }, params) => {
  return await Vue.prototype.$bahngleis.get(
    `/api/LogStock/ExportLog?ecommerceName=${rootState.ecommerce.Name}`,
    {
      params: params,
      responseType: "blob",
    }
  );
}

const getLogOrders = async ({ rootState, commit }, payload) => {
  let params = Object.assign({ ...payload }, { ecommerceName: rootState.ecommerce.Name });
  return await Vue.prototype.$bahngleis.get(`logs/GetErrorListWithoutIntegrating`, { params: params }).then(response => {
    commit('SET_LOG_ORDERS', response.data.Items);
    return Promise.resolve(response);
  }).catch(error => {
    return Promise.reject(error);
  });
}

const getLogCustomEvent = async ({ rootState, commit }, id) => {
  return await Vue.prototype.$bahngleis.get(`LogAction/getlogsofentity?identity=${rootState.ecommerce.Name}&entity=ManualCaptureStock`);
}

const getLastLogStockBySku = async ({ rootState }, sku) => {
  return await Vue.prototype.$bahngleis.get(`api/LogStock/last-by-sku?sku=${sku}&ecommerceName=${rootState.ecommerce.Name}`)
    .then((response) => { return response.data });
}

const getLastLogPriceBySku = async ({ rootState }, sku) => {
  return await Vue.prototype.$bahngleis.get(`api/LogPrice/last-by-sku?sku=${sku}&ecommerceName=${rootState.ecommerce.Name}`)
    .then((response) => { return response.data });
}

const getLogActions = async ({ rootState, commit }, payload) => {
  let params = Object.assign({
    entity: 'Order',
    ecommerceName: payload.allEcommerce ? null : rootState.ecommerce.Name,
    currentPage: payload.pageNumber,
    perPage: payload.pageSize,
    orderNumber: payload.OrderNumber,
    from: payload.From,
    to: payload.To,
    username: payload.Username
  });

  return await Vue.prototype.$bahngleis.get(`LogAction/GetByEcommerce`, { params: params })
    .then(response => {
      commit('SET_LOG_ACTIONS', response.data.Items);
      return Promise.resolve(response);
    }).catch(error => {
      return Promise.reject(error);
    });
}

const getUsers = async ({ rootState, commit }) => {
  return await Vue.prototype.$keycloak_api.get(rootState.realm + `/users?briefRepresentation=true`)
    .then(response => {
      commit('SET_USERS', response.data.sort((a, b) => {
        if (a.username < b.username) {
          return -1;
        }
        if (a.username > b.username) {
          return 1;
        }
        return 0;
      }));
    });
}

const exportLogAction = async ({ rootState }, payload) => {
  let params = Object.assign({
    entity: 'Order',
    ecommerceName: payload.allEcommerce ? null : rootState.ecommerce.Name,
    currentPage: payload.pageNumber,
    perPage: payload.pageSize,
    from: payload.From,
    to: payload.To,
    orderNumber: payload.OrderNumber,
    username: payload.Username
  });

  return await Vue.prototype.$bahngleis.get(
    `/api/Export/ExportLogAction`,
    {
      params: params,
      responseType: "blob"
    }
  );
}

export default {
  getLogActionPaginated,
  getLogOrders,
  getLogStock,
  exportLogStock,
  getLogCustomEvent,
  getLastLogStockBySku,
  getLastLogPriceBySku,
  getLogActions,
  getUsers,
  exportLogAction
}
