const SET_CUSTOM_FIELDS = (state, customFields) => {
    state.customFields = customFields;
}

const SET_CURRENT_CUSTOM_FIELD = (state, currentCustomField) => {
  state.currentCustomField = currentCustomField;
}

const SET_ORDER_RULE_CURRENT = (state, orderRuleCurrent) => {
  state.orderRuleCurrent = orderRuleCurrent;
}

const SET_LOGS = (state, logs) => {
  state.logs = logs;
}

const SET_PAYMENT_METHODS = (state, paymentMethods) => {
  state.paymentMethods = paymentMethods;
}

const SET_SHIPPING_METHODS = (state, shippingMethods) => {
  state.shippingMethods = shippingMethods;
}

const SET_WAREHOUSES = (state, warehouses) => {
  state.warehouses = warehouses;
}

const SET_BRANCHS = (state, branchs) => {
  state.branchs = branchs;
}

const SET_CHANNELS = (state, channels) => {
  state.channels = channels;
}

export default {
  SET_CUSTOM_FIELDS,
  SET_CURRENT_CUSTOM_FIELD,
  SET_ORDER_RULE_CURRENT,
  SET_LOGS,
  SET_PAYMENT_METHODS,
  SET_SHIPPING_METHODS,
  SET_WAREHOUSES,
  SET_BRANCHS,
  SET_CHANNELS
}
