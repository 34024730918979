<template>
    <img :src="source" alt="">
</template>
<script>
export default {
    props : {
        src : { type: String },
        color: { type: String, default : '#9a9a9a'}
    },
    computed: {
        source(){
            return `https://api.iconify.design/${this.src}.svg?color=%23${this.color.replace(/^#/, '')}`;
        }
    },
}
</script>