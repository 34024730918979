const SET_LOG_ORDERS = (state, model) => {
    state.logOrders = model;
}

const SET_LOG_ACTIONS = (state, model) => {
    state.logActions = model;
}

const SET_USERS = (state, model) => {
    state.users = model;
}

export default {
    SET_LOG_ORDERS,
    SET_LOG_ACTIONS,
    SET_USERS
}
