import Vue from "vue";

const getConfigurations = async ({rootState, commit}) => {
  let responseConfiguration = await Vue.prototype.$bahngleisWithoutLoading.get(
    `Configuration?ecommerceName=${rootState.ecommerce.Name}&companyName=${rootState.realm}`
  ).then(res => { return res.data});

  if (!responseConfiguration || responseConfiguration.length === 0) {
    let seedConfiguration = await Vue.prototype.$bahngleisWithoutLoading.post(
      `Configuration/SeedConfiguration/${rootState.ecommerce.Name}`
    );

    if (seedConfiguration) {
      responseConfiguration = await Vue.prototype.$bahngleisWithoutLoading.get(
        `Configuration?ecommerceName=${rootState.ecommerce.Name}&companyName=${rootState.realm}`
      ).then(res => { return res.data});
    }
  }

  if(responseConfiguration && responseConfiguration.length > 0){
    commit('SET_CONFIGURATIONS', responseConfiguration);
    return responseConfiguration;
  }
  else
    throw new Error("Não foi possível carregar as configurações");
}

const updateConfigurations = async ({rootState}, payload) => {
  return await Vue.prototype.$bahngleis.put("Configuration", payload);
}

const updateAddon = async ({rootState}, payload) => {
  return await Vue.prototype.$bahngleis.post("Addon", payload);
}

const getRecurringJobs = async ({commit}) => {
  return await Vue.prototype.$bahngleisWithoutLoading.get(
    `RecurringJobs?ecommerceName=${rootState.ecommerce.Name}`
  ).then(res => {
    commit('SET_RECURRING_JOBS', res.data);
    return res;
  });
}

const loadConfigurationsSAP = async ({rootState, commit}) => {
  return await Vue.prototype.$bahngleisWithoutLoading
    .get("Configuration/Filter?companyName=" + rootState.realm + "&ecommerceName=" + rootState.ecommerce.Name)
    .then((resp) => {
        commit('SET_CONFIGURATIONS_SAP', resp.data);
        return resp;
    })
    .catch((err) => {
        throw err;
    }
  );
}

const getConfigurationByKeyWithEcommerce = async ({rootState, commit}, key) => {
  return await Vue.prototype.$bahngleis.get(
    `Configuration/GetByKeyWithEcommerce?key=${key}&ecommerceName=${rootState.ecommerce.Name}`
  );
}

const getConfigurationByKeys = async ({}, params) => {
  let query = `ecommerceName=${params.ecommerceName}`;
  params.keys.forEach(element => {
      query += `&keys=${element}`;
  });
  return await Vue.prototype.$bahngleis.get(`Configuration?${query}`);
}

const getWarehouses = ({rootState}) => {
  return Vue.prototype.$bahngleis
        .get("configuration/warehouse", {
          params: {
            ecommerceName: rootState.ecommerce.Name,
            active: true
          }
        });
}


const createErpDocuments = async ({}, payload) => {
  return await Vue.prototype.$bahngleis.post("ErpDocuments", payload);
}

export default {
  getConfigurations,
  getRecurringJobs,
  loadConfigurationsSAP,
  updateConfigurations,
  updateAddon,
  getConfigurationByKeyWithEcommerce,
  getWarehouses,
  getConfigurationByKeys,
  createErpDocuments
}
