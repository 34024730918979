import actions from "./actions";
import mutations from './mutations';
import getters from './getters';

const state = {
  customFields: [],
  logs: [],
  orderRuleCurrent: {},
  paymentMethods: [],
  shippingMethods: [],
  warehouses: [],
  branchs: [],
  channels: [],
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
