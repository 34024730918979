import Vue from "vue";

const getPaymentMethods = async ({ rootState, commit }, params) => {
  params = params || {};
  params = Object.assign(params, { ecommerceName: rootState.ecommerce.Name });
  return await Vue.prototype.$bahngleis.get(`PaymentMethod/GetPaginated`, { params: params }).then(response => {
    commit('SET_PAYMENTMETHODS', response.data.PaymentMenthods);
    return response;
  });
}

const removePaymentMethod = async ({ rootState }, id) => {
  return await Vue.prototype.$bahngleis
    .delete(`/PaymentMethod/${id}`)
    .then(res => { return Promise.resolve(res) })
    .catch(err => { return Promise.reject(err.response.data) });
}

const getPaymentMethodLog = async ({ commit }, id) => {
  return await Vue.prototype.$bahngleis
    .get(`/LogAction/getlogsofentity?identity=${id}&entity=PaymentMethod`)
    .then(response => {
      commit('SET_LOGS', response.data);
    });
}

const getCreditCardCodes = async ({ rootState, commit }) => {
  return await Vue.prototype.$bahngleis.get("SAP/credit-cards", { params: { ecommerceName: rootState.ecommerce.Name } })
    .then(response => {
      commit('SET_CREDIT_CARD_CODES', response.data);
    });
}

const getPaymentMethodCodes = async ({ rootState, commit }) => {
  return await Vue.prototype.$bahngleis.get("SAP/payment-methods", { params: { ecommerceName: rootState.ecommerce.Name } })
    .then(response => {
      commit('SET_PAYMENT_METHOD_CODES', response.data);
    });
}

const getPaymentMethodTypes = async ({ rootState, commit }) => {
  return await Vue.prototype.$bahngleis.get("SAP/chart-of-account", { params: { ecommerceName: rootState.ecommerce.Name } })
    .then(response => {
      commit('SET_PAYMENT_METHOD_TYPES', response.data);
    });
}

const getERPPaymentMethods = async ({ rootState, commit }) => {
  return await Vue.prototype.$bahngleis.get(`SAP/wizard-payment-methods`, { params: { ecommerceName: rootState.ecommerce.Name } })
    .then(response => {
      commit('SET_ERP_PAYMENT_METHODS', response.data);
    });
}

const getPaymentConditions = async ({ rootState, commit }) => {
  return await Vue.prototype.$bahngleis.get(`SAP/payment-conditions`, { params: { ecommerceName: rootState.ecommerce.Name } })
    .then(response => {
      commit('SET_PAYMENT_CONDITIONS', response.data);
    });
}

const savePaymentMethod = async ({ rootState }, payload) => {
  payload = Object.assign(payload, { ecommerceName: rootState.ecommerce.Name });
  return await Vue.prototype.$bahngleis
    .post(`PaymentMethod/`, payload);
}

const updatePaymentMethod = async ({ rootState }, payload) => {
  payload = Object.assign(payload, { ecommerceName: rootState.ecommerce.Name });
  return await Vue.prototype.$bahngleis
    .put(`PaymentMethod/${payload.Id}`, payload);
}

const insertECompayment = async ({ }, payload) => {
  return await Vue.prototype.$bahngleisWithoutLoading.post("SAP/create-ecompayment", payload);
}

const updateECompayment = async ({ }, payload) => {
  return await Vue.prototype.$bahngleisWithoutLoading.put("SAP/patch-ecompayment", payload);
}

const importPaymentMethods = async ({ rootState }, formData) => {
  let config = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  };

  return await Vue.prototype.$bahngleis.post(
    `PaymentMethod/Import?ecommerceName=${rootState.ecommerce.Name}`,
    formData, config);
}

export default {
  getPaymentMethods,
  removePaymentMethod,
  getCreditCardCodes,
  getPaymentMethodCodes,
  getPaymentMethodTypes,
  getERPPaymentMethods,
  getPaymentConditions,
  savePaymentMethod,
  updatePaymentMethod,
  insertECompayment,
  updateECompayment,
  getPaymentMethodLog,
  importPaymentMethods
}
