<template>
  <div class="bodyLoading">
    <svg width="200" height="200" viewBox="0 0 40 60">
      <polygon
        class="triangle"
        fill="none"
        stroke="#fff"
        stroke-width="1"
        points="16,1 32,32 1,32" />
      <text class="loading" x="0" y="45" fill="#fff"> Carregando... </text>
    </svg>
  </div>
</template>

<script>
  export default {
    name: "loading-screen"
  };
</script>

<style>
  @import './Loading.css';
</style>

