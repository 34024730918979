const SET_COSTCENTERCONFIGURATIONS = (state, costCenterConfigurations) => {
    state.costCenterConfigurations = costCenterConfigurations
}

const SET_LOGS = (state, logs) => {
    state.logs = logs
}

const SET_COSTCENTERCONFIGURATION_CURRENT = (state, costCenterConfigurations) => {
    state.costCenterConfigurationCurrent = costCenterConfigurations;
}

const SET_COSTCENTER = (state, model) => {
    state.costCenterCurrent = model;
}

export default {
    SET_COSTCENTERCONFIGURATIONS,
    SET_LOGS,
    SET_COSTCENTERCONFIGURATION_CURRENT,
    SET_COSTCENTER
}