import Vue from "vue";

const getOrders = async ({ rootState, commit }, payload) => {
  let params = { ...payload };

  if (!params.hasOwnProperty('ecommerceName')) {
    let ecommerceName = rootState.ecommerce.Name;
    let ecommerceCurrent = sessionStorage.getItem("ecommerceCurrent");
    if (ecommerceCurrent) {
      let ecommerce = rootState.ecommerceList.find(e => e.Id === parseInt(ecommerceCurrent));
      if (ecommerce) {
        ecommerceName = ecommerce.Name;
      }
    }
    params.ecommerceName = ecommerceName;
  }

  const queryString = Object.entries(params)
    .filter(([key, value]) => value !== null)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map(item => `${key}=${encodeURIComponent(item)}`).join('&');
      }
      return `${key}=${encodeURIComponent(value)}`;
    })
    .join('&');

  return await Vue.prototype.$bahngleis.get(`Order/FilterOrders?${queryString}`).then(response => {
    commit('SET_ORDER', response.data.Items);
    return Promise.resolve(response);
  }).catch(error => {
    return Promise.reject(error);
  });
}

const getOrderByNumber = async ({ commit }, params) => {
  return await Vue.prototype.$bahngleis.get(`Order/GetOrder`, { params: params }).then(response => {
    commit('SET_ORDER_CURRENT', response.data);
    return Promise.resolve(response);
  }).catch(error => {
    return Promise.reject(error);
  });
}

const logViewedCustomFields = async ({ }, payload) => {
  return await Vue.prototype.$bahngleis.post(`Order/LogViewedCustomFields`, payload)
    .then(res => { return Promise.resolve(res) })
    .catch(err => { return Promise.reject(err.response.data) });
}

const getItemTransfer = async ({ }, orderId) => {
  return await Vue.prototype.$bahngleis.get(`Order/ItemTransfer?orderId=${orderId}`)
    .then(response => {
      return Promise.resolve(response);
    }).catch(error => {
      return Promise.reject(error.response.data);
    });
}

const createOrder = async ({ rootState, commit }, payload) => {
  payload.EcommerceName = rootState.ecommerce.Name;
  return await Vue.prototype.$bahngleis.post("Order", payload);
}

const updateOrder = async ({ commit }, payload) => {
  return await Vue.prototype.$bahngleis.put(`Order/update`, payload)
    .then(res => { return Promise.resolve(res) })
    .catch(err => { return Promise.reject(err.response.data) });
}

const updateCustomFields = async ({ commit }, payload) => {
  return await Vue.prototype.$bahngleis.patch(`Order/UpdateCustomFields`, payload)
    .then(res => { return Promise.resolve(res) })
    .catch(err => { return Promise.reject(err.response.data) });
}

const removeOrder = async ({ }, id) => {
  return await Vue.prototype.$bahngleis.delete(`Order/${id}`);
}

const cancelOrder = async ({ rootState, commit }, params) => {
  return await Vue.prototype.$bahngleis.get(`/Order/CancelOrderBahn/?id=${params.Id}&number=${params.Number}&ecommerceName=${params.EcommerceName}&currentUserName=${params.UserName}&reason=${params.Reason}`)
    .then(response => {
      return Promise.resolve(response);
    }).catch(error => {
      return Promise.reject(error.response.data);
    });
}

const exportOrderError = async ({ rootState }, params) => {
  params = addEcommerceNameIfNeeded(params, rootState);

  var query = getParamsToExportOrders(params);

  return await Vue.prototype.$bahngleis.get(
    `/Order/ExportOrderError`,
    {
      params: query,
      responseType: "blob"
    }
  );
}

const exportOrderCanceled = async ({ rootState }, params) => {
  params = addEcommerceNameIfNeeded(params, rootState);

  var query = getParamsToExportOrders(params);
  return await Vue.prototype.$bahngleis.get(
    `/Order/ExportOrderCanceled`,
    {
      params: query,
      responseType: "blob"
    }
  );
}

const exportOrderAwaitingCancellation = async ({ rootState }, params) => {
  params = addEcommerceNameIfNeeded(params, rootState);

  var query = getParamsToExportOrders(params);

  return await Vue.prototype.$bahngleis.get(
    `/Order/ExportOrderAwaitingCancellation`,
    {
      params: query,
      responseType: "blob"
    }
  );
}

const addEcommerceNameIfNeeded = (params, rootState) => {
  if (!params.hasOwnProperty('ecommerceName')) {
    let ecommerceName = rootState.ecommerce.Name;
    let ecommerceCurrent = sessionStorage.getItem("ecommerceCurrent");
    if (ecommerceCurrent) {
      let ecommerce = rootState.ecommerceList.find(e => e.Id === parseInt(ecommerceCurrent));
      if (ecommerce)
        ecommerceName = ecommerce.Name;
    }
    params = Object.assign({ ...params }, { ecommerceName: ecommerceName });
  }
  return params;
}

const getParamsToExportOrders = (params) => {
  const query = new URLSearchParams();
  query.append('ecommerceName', params.ecommerceName);

  for (const orderType of params.orderTypes) {
    query.append('orderTypes', orderType);
  }

  if (params.from)
    query.append('from', params.from);

  if (params.to)
    query.append('to', params.to);

  if (params.warehouses) {
    for (var i = 0; i < params.warehouses.length; i++) {
      query.append('warehouses', params.warehouses[i]);
    }
  }

  return query;
}

const exportOrderAll = async ({ rootState }, params) => {

  if (!params.hasOwnProperty('ecommerceName')) {
    let ecommerceName = rootState.ecommerce.Name;
    let ecommerceCurrent = sessionStorage.getItem("ecommerceCurrent");
    if (ecommerceCurrent) {
      let ecommerce = rootState.ecommerceList.find(e => e.Id === parseInt(ecommerceCurrent));
      if (ecommerce)
        ecommerceName = ecommerce.Name;
    }
    params = Object.assign({ ...params }, { ecommerceName: ecommerceName });
  }

  let queryString = "";

  if (params.orderNumber) queryString += "&orderNumber=" + params.orderNumber;

  if (params.from) queryString += "&from=" + params.from;

  if (params.to) queryString += "&to=" + params.to;

  if (params.status) {
    for (var i = 0; i < params.status.length; i++) {
      queryString += "&status=" + params.status[i];
    }
  }

  if (params.channel) {
    for (var i = 0; i < params.channel.length; i++) {
      queryString += "&channel=" + channel[i];
    }
  }

  return await Vue.prototype.$bahngleis.get(
    `/Order/ExportOrders?ecommerceName=${params.ecommerceName}${queryString}`,
    {
      responseType: "blob",
    }
  );
}

const getErpDocuments = async ({ rootState, commit }) => {
  return await Vue.prototype.$bahngleis.get(
    `/ErpDocuments/GetByEcommerceName?ecommerceName=${rootState.ecommerce.Name}`
  )
    .then(response => {
      commit('SET_ERP_DOCUMENTS', response.data);
      return Promise.resolve(response);
    })
    .catch((error) => { return Promise.reject(error.response.data) })
}

const requeueSelected = async ({ }, payload) => {
  return await Vue.prototype.$bahngleis
    .post("/dashboard/RequeueOrders", payload);
}

const retransfer = async ({ }, payload) => {
  return await Vue.prototype.$bahngleis
    .put("Order/Retransfer", payload)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => { return Promise.reject(error.response.data) });
}

const updateWarehouse = async ({ rootState }, payload) => {
  return await Vue.prototype.$bahngleis.put(`Order/${rootState.ecommerce.Name}/update-warehouse`, payload);
}

const getLogInternal = async ({ commit }, params) => {
  return await Vue.prototype.$bahngleis.get(`logs/internal`, { params: params }).then(response => {
    commit('SET_LOG_INTERNAL', response.data);
    return Promise.resolve(response);
  }).catch(error => {
    return Promise.reject(error);
  });
}

const getLastLogError = async ({ }, params) => {
  return await Vue.prototype.$bahngleis.get(`logs/getLastLogError?number=${params.Number}&ecommerceName=${params.EcommerceName}`)
    .then(response => {
      return Promise.resolve(response);
    }).catch(error => {
      return Promise.reject(error.response.data);
    });
}

const getLastFiveLogs = async ({ }, params) => {
  return await Vue.prototype.$bahngleis.get(`/logs/last-five?orderNumber=${params.number}&ecommerceName=${params.ecommerceName}&InternalLog=false&PageSize=5`)
    .then(response => {
      return Promise.resolve(response);
    }).catch(error => {
      return Promise.reject(error.response.data);
    });
}

const getHelpCenter = async ({ commit }) => {
  return await Vue.prototype.$bahngleis.get(`HelpCenter`)
    .then(response => {
      commit('SET_HELP_CENTER', response.data);
      return Promise.resolve(response.data);
    }).catch(error => {
      return Promise.reject(error.response.data);
    });
}

const cancelSelected = async ({ }, payload) => {
  return await Vue.prototype.$bahngleis
    .post("/Order/CancelOrders", payload);
}

const getOrderEvents = async ({ }, params) => {
  return await Vue.prototype.$bahngleis.get(`/Order/GetOrderEventsFromExternalApi?orderNumber=${params.orderNumber}&ecommerceName=${params.ecommerceName}`)
    .then(response => {
      return Promise.resolve(response);
    }).catch(error => {
      return Promise.reject(error.response.data);
    });
}

const buildReturnInvoices = async ({ }, params) => {
  let config = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  };

  return await Vue.prototype.$bahngleis.post(
    `ManualImport/BuildReturnInvoicesFiles?recipient=${params.email}`,
    params.formData, config);
}

export default {
  getOrders,
  createOrder,
  updateOrder,
  removeOrder,
  cancelOrder,
  exportOrderError,
  exportOrderCanceled,
  exportOrderAwaitingCancellation,
  exportOrderAll,
  getErpDocuments,
  requeueSelected,
  getOrderByNumber,
  getItemTransfer,
  retransfer,
  updateWarehouse,
  getLogInternal,
  getLastLogError,
  getHelpCenter,
  getLastFiveLogs,
  cancelSelected,
  logViewedCustomFields,
  updateCustomFields,
  getOrderEvents,
  buildReturnInvoices
}
