import Vue from "vue";

const getUsages = async ({ rootState, commit }, params) => {
    let query = "";
    if (params.type && params.name)
        query = `&${params.type}=${params.name}`;

    return await Vue.prototype.$bahngleis.get(`Usage/GetPaginated?ecommerceName=${rootState.ecommerce.Name}&currentPage=${params.currentPage}&perPage=${params.perPage}${query}`)
        .then(response => {
            commit('SET_USAGES', response.data);
        });
}

const getLogUsage = async ({ rootState, commit }, id) => {
    return await Vue.prototype.$bahngleis.get(`LogAction/getlogsofentity?identity=${id}&entity=Usage`)
        .then(response => {
            commit('SET_LOGS', response.data);
        });
}

const createUsage = async ({ rootState, commit }, payload) => {
    payload.EcommerceName = rootState.ecommerce.Name;
    return await Vue.prototype.$bahngleis.post("Usage", payload);
}

const updateUsage = async ({ commit }, payload) => {
    return await Vue.prototype.$bahngleis.put(`Usage/${payload.Id}`, payload);
}

const removeUsage = async ({ }, id) => {
    return await Vue.prototype.$bahngleis.delete(`Usage/${id}`);
}

export default {
    getUsages,
    getLogUsage,
    createUsage,
    updateUsage,
    removeUsage
}