const SET_OBSERVATION_DOCUMENTS = (state, observationDocuments) => {
    state.observationDocuments = observationDocuments
}

const SET_LOGS = (state, logs) => {
    state.logs = logs
}

const SET_OBSERVATION_DOCUMENT_CURRENT = (state, observationDocument) => {
    state.observationDocumentCurrent = observationDocument;
}

export default {
    SET_OBSERVATION_DOCUMENTS,
    SET_LOGS,
    SET_OBSERVATION_DOCUMENT_CURRENT
}