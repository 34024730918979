const SET_GROUPS = (state, groups) => {
    state.groups = groups;
}

const SET_GROUP_CURRENT = (state, group) => {
    state.groupCurrent = group;
}

const SET_GROUPS_COUNT = (state, count) => {
    state.groupCount = count;
}
const SET_ROLES = (state, roles) => {
    state.roles = roles;
}

const SET_CLIENTS = (state, roles) => {
    state.clients = roles;
}

const SET_CLIENT_ROLE = (state, clientRole) =>{
    state.clientRole = clientRole;
}

const SET_GROUP_PERMISSIONS = (state, permissions) => {
    state.groupPermissions = permissions;
}

const SET_GROUP_CLIENTS_PERMISSIONS = (state, permissions) => {
    state.groupClientsPermissions = permissions;
}

const SET_LOGS = (state, logs) => {
    state.logs = logs
}

export default {
    SET_GROUPS,
    SET_GROUP_CURRENT,
    SET_GROUPS_COUNT,
    SET_ROLES,
    SET_CLIENTS,
    SET_CLIENT_ROLE,
    SET_GROUP_PERMISSIONS,
    SET_GROUP_CLIENTS_PERMISSIONS,
    SET_LOGS
}