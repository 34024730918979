import actions from "./actions";
import mutations from './mutations';

const state = {
    notificationEmails: [],
    notificationEmailLogs: [],
    notificationEmailCurrent: {},
    logs: []
}
export default {
    namespaced: true,
    state,
    actions,
    mutations
}
