import Vue from 'vue';

const getEcommerces = async ({commit}) => {
    await Vue.prototype.$bahngleis.get("Ecommerce")
    .then(res => {
        commit('SET_ECOMMERCES', res.data);
        return Promise.resolve(res);
    })
    .catch(error => { return Promise.reject(error.response.data) })
}

const saveErpConfiguration = async ({commit}, payload) => {
    return await Vue.prototype.$bahngleis.post("Erp", payload)
    .then(response => {
        commit('SET_ERP', response.data);
        return Promise.resolve(response);
    })
    .catch(error => { return Promise.reject(error.response.data)})
}

const saveEcommerce = async ({commit}, payload) => {
    return await Vue.prototype.$bahngleis.post("Ecommerce", payload)
    .then(response => {
        commit('SET_ECOMMERCE', response.data);
        return Promise.resolve(response);
    })
    .catch(error => { return Promise.reject(error.response.data)})
}

const executeSeeders = async ({}, ecommerceName) => {
    return await Vue.prototype.$bahngleisWithoutLoading.post(`Dashboard/execute-seeders/${ecommerceName}`);
}

export default {
    getEcommerces,
    saveErpConfiguration,
    saveEcommerce,
    executeSeeders
}
