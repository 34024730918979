import actions from "./actions";
import mutations from './mutations';

const state = {
    logOrders: [],
    logActions: [],
    users: [],
}

export default {
    namespaced : true,
    state,
    actions,
    mutations
}
