import Vue from "vue";

const getGroups = async ({ rootState, commit }, params) => {
    return await Vue.prototype.$keycloak_api.get(params.realm + `/groups`)
        .then(response => {
            commit('SET_GROUPS', response.data);
        });
}

const getGroupPermissions = async ({ rootState, commit }, id) => {
    return await Vue.prototype.$keycloak_api.get(`${rootState.realm}/groups/${id}/role-mappings/realm/`)
        .then(response => {
            commit('SET_GROUP_PERMISSIONS', response.data);

            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
}

const getGroupClientPermissions = async ({ rootState, commit }, params) => {
    return await Vue.prototype.$keycloak_api.get(`${rootState.realm}/groups/${params.id}/role-mappings/clients/${params.clientId}`)
        .then(response => {
            commit('SET_GROUP_CLIENTS_PERMISSIONS', response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
}

const getRoles = async ({ rootState, commit }, params) => {
    return await Vue.prototype.$keycloak_api.get(params.realm + `/roles`)
        .then(response => {
            commit('SET_ROLES', response.data);
        });
}

const getClientId = async ({ rootState, commit }, params) => {
    const response = await Vue.prototype.$keycloak_api.get(
        params.realm + `/clients?clientId=realm-management`);

    return response.data[0].id;
}

const getClientRole = async ({ rootState, commit }, params) => {
    return await Vue.prototype.$keycloak_api.get(
        `${params.realm}/clients/${params.clientId}/roles/manage-users`)
        .then(response => {
            commit('SET_CLIENT_ROLE', response.data);

            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
}

const getGroupsCount = async ({ commit }, params) => {
    return await Vue.prototype.$keycloak_api.get(params.realm + `/groups/count`)
        .then(response => {
            commit('SET_GROUPS_COUNT', response.data.count);
        });
}


const getGroupLogs = async ({ rootState, commit }, id) => {
    return await Vue.prototype.$bahngleis.get(`LogAction/getlogsofentity?identity=${id}&entity=Group`)
      .then(response => {
        commit('SET_LOGS', response.data);
      });
  }

const createGroup = async ({ rootState, commit }, payload) => {
    return await Vue.prototype.$bahngleis.post("Keycloak/Group", payload.formData);
}

const updateGroup = async ({ rootState, commit }, payload) => {
    return await Vue.prototype.$bahngleis.put("Keycloak/Group", payload.formData);
}

const removeGroup = async ({ commit }, payload) => {
    return await Vue.prototype.$bahngleis.delete(`Keycloak/Group/${payload.id}`);
}

export default {
    createGroup,
    getClientId,
    getClientRole,
    getGroupClientPermissions,
    getGroupPermissions,
    getGroups,
    getGroupsCount,
    getRoles,
    getGroupLogs,
    removeGroup,
    updateGroup
}