<template>
  <div class="fixed-plugin" v-click-outside="closeDropDown">
    <!-- <div class="dropdown show-dropdown" :class="{ show: isOpen }">
      <a data-toggle="dropdown" class="settings-icon">
        <i class="fa fa-cog fa-2x" @click="toggleDropDown"></i>
      </a>
      <ul class="dropdown-menu" :class="{ show: isOpen }">
        <li class="header-title">Deseja visualizar qual loja?</li>

        <li
          class="adjustments-line"
          v-for="ecommerce in this.$store.ecommerceList"
          :key="ecommerce.id"
        >
          <div class="togglebutton switch-sidebar-mini">
            <button
              class="custom-btn-ecommerce btn-ecommerces"
              @click="changeEcommerce(ecommerce.name)"
            >
              {{ ecommerce.name }}
            </button>
          </div>
        </li>

        <li class="button-container mt-4"></li>
      </ul>
    </div> -->
  </div>
</template>
<script>
import { BaseSwitch } from "src/components";

export default {
  name: "sidebar-share",
  components: {
    BaseSwitch
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    toggleDropDown() {
      this.isOpen = !this.isOpen;
    },
    closeDropDown() {
      this.isOpen = false;
    }
  }
};
</script>
<style scoped lang="scss">
@import "~@/assets/sass/dashboard/custom/variables";

.settings-icon {
  cursor: pointer;
}

.badge-vue {
  background-color: $vue;
}
</style>
