const priceListsToTable = state => {
  const priceLists = state.priceLists.sort((a, b) => a.Id - b.Id).map(priceList => {
    return {
      id: priceList.Id,
      name: priceList.Name,
      ecommerceName: priceList.EcommerceName,
      priceListCode: priceList.PriceListCode,
      active: priceList.Active,
      additionalList: priceList.AdditionalList,
      isMain: priceList.IsMain,
      isB2B: priceList.IsB2B,
      isDiscount: priceList.IsDiscount,
      ecommercePriceListId: priceList.EcommercePriceListId,
    };
  });

  return priceLists;
};

const priceListsToSelectField = state => {
  const priceLists = state.priceLists.sort((a, b) => a.Id - b.Id).map(priceList => {
    return {
      Label: priceList.Name,
      Value: priceList.Id
    };
  });

  return priceLists;
}

export default {
  priceListsToTable,
  priceListsToSelectField
}
