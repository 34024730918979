import firebase from 'firebase/app'
import 'firebase/messaging'

const firebaseConfig = {
  apiKey: "AIzaSyBh9SIz7gYbvNNpbrqFpbse4Dg1mH5ckoM",
  authDomain: "bahn-notifications.firebaseapp.com",
  projectId: "bahn-notifications",
  storageBucket: "bahn-notifications.appspot.com",
  messagingSenderId: "902006006121",
  appId: "1:902006006121:web:d510cf511e7b59ff9a1df8",
  measurementId: "G-Z1KGP205B6"
};

firebase.initializeApp(firebaseConfig)

export const messaging = firebase.messaging()
