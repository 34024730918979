import actions from "./actions";
import mutations from './mutations';

const state = {
    addons: [],
    addonCurrent: {},
    logs: []
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}