import Vue from "vue";

const sendRegistrationToken = ({ rootState }, registrationToken) => {
    let sentToken = localStorage.getItem("sentToken");
    if (sentToken)
        return Promise.resolve();

    return Vue.prototype.$nordware_config.post("/api/v1/Client/firebase-registration-token", {
        Realm: rootState.realm,
        RegistrationToken: registrationToken
    }).then(() => {
        localStorage.setItem("sentToken", true);
        return Promise.resolve();
    });
   
}

const sendPushNotification = async ({ }, payload) => {
    return await Vue.prototype.$nordware_config.post("/api/v1/Client/send-push-notification", payload);
}

const getDashboardSales = async ({rootState}, params) => {
    params.ecommerceName = rootState.ecommerce.Name;
    return await Vue.prototype.$bahngleis.get('dashboard/DashboardSales',{ params : params });
}

export default {
    sendRegistrationToken,
    sendPushNotification,
    getDashboardSales
}
