import Vue from "vue";

const getAddons = async ({ commit }, params) => {
    await Vue.prototype.$bahngleis.get(`Addon/GetPaginated?currentPage=${params.currentPage}&perPage=${params.perPage}`)
        .then(response => {
            var addons = response.data.Data;
            commit('SET_ADDONS', addons);
            return Promise.resolve(addons);
        }).catch(error => {
            return Promise.reject(error);
        });
}

const getLogAddon = async ({ commit }, id) => {
    await Vue.prototype.$bahngleis.get(`LogAction/getlogsofentity?identity=${id}&entity=Addon`)
        .then(response => {
            commit('SET_LOGS', response.data);
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        });
}

const createAddon = async ({ rootState }, payload) => {
    payload.EcommerceName = rootState.ecommerce.Name;
    return await Vue.prototype.$bahngleis.post("Addon/CreateAddon", payload)
        .then(res => { return Promise.resolve(res) })
        .catch(err => { return Promise.reject(err.response.data) });
}

const updateAddon = async (_, payload) => {
    return await Vue.prototype.$bahngleis.put(`Addon/${payload.Id}`, payload)
        .then(res => { return Promise.resolve(res) })
        .catch(err => { return Promise.reject(err.response.data) });
}

const removeAddon = async (_, id) => {
    return await Vue.prototype.$bahngleis.delete(`Addon/${id}`)
        .then(res => { return Promise.resolve(res) })
        .catch(err => { return Promise.reject(err.response.data) });;
}

export default {
    getAddons,
    getLogAddon,
    createAddon,
    updateAddon,
    removeAddon
}
