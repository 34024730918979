<template>
  <div>
    <card card-body-classes="table-full-width">
      <h4 slot="header" class="card-title">{{ title }}</h4>
      <div>
        <el-table :data="queriedData">
          <el-table-column label="Campo" align="left" min-width="150">
            <template slot-scope="props">
              <div v-for="(item, index) in formatDescription(props.row.Description)" :key="index">
                {{ item.field || '-' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column  v-if="showOldValue" label="Valor anterior" align="left" min-width="100">
            <template slot-scope="props">
              <div v-for="(item, index) in formatDescription(props.row.Description)" :key="index">
                <span v-if="isDate(item.oldValue)">{{ formatDate(item.oldValue) }}</span>
                <span v-else>{{ strippedHtml(item.oldValue) }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="labelNewValue" align="left" min-width="100">
            <template slot-scope="props">
              <div v-for="(item, index) in formatDescription(props.row.Description)" :key="index">
                <span v-if="isDate(item.newValue)">{{ formatDate(item.newValue) }}</span>
                <span v-else>{{ strippedHtml(item.newValue) }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="User" label="Usuário" align="left"></el-table-column>
          <el-table-column prop="Date" label="Data" :formatter="dateFormatter" align="left"></el-table-column>
        </el-table>
        <div v-if="dataLog.length > 5" slot="footer"
          class="col-12 d-flex justify-content-center justify-content-between flex-wrap">
          <div class="col-5"></div>
          <div class="col-2">
            <label>
              Exibindo
              <span class="primary-text">{{ from + 1 }}</span> -
              <span class="primary-text">{{ to }}</span> de
              <span class="primary-text">{{ total }}</span> registros
            </label>
          </div>
          <div class="col-3">
            <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
              :total="total"></base-pagination>
          </div>
          <div class="col-2">
            <el-select class="select-primary mb-3 pagination-select" style="width: 100px !important"
              v-model="pagination.perPage" placeholder="Per page">
              <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                :value="item"></el-option>
            </el-select>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import moment from "moment";
import { BasePagination, BaseRadio } from "src/components";
export default {
  name: 'UpdateLog',
  props: {
    title: { type: String, default: 'Histórico de Atualizações' },
    showOldValue: { type: Boolean, default: true },
    labelNewValue: { type: String, default: 'Novo valor' },
    dataLog: {
      type: Array,
      required: true,
    },
  },
  components: {
    BaseRadio,
    BasePagination,
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      }
    }
  },
  computed: {
    queriedData() {
      let result = this.dataLog;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.dataLog.length;
    }
  },
  methods: {
    formatDescription(description) {
      let jsonRegex = /\[|\]|{|}/ig;
      let descriptionArray = []
      let obj = JSON.parse(description)

      for (const key in obj) {
        let oldValue = obj[key].Item1;
        let newValue = obj[key].Item2;

        if (newValue === 'true') {
          newValue = true;
          oldValue = false;
        } else if (newValue === 'false') {
          newValue = false;
          oldValue = true;
        }

        if (typeof newValue === 'boolean') {
          oldValue = oldValue ? 'ON' : 'OFF';
          newValue = newValue ? 'ON' : 'OFF';
        }

        if (typeof newValue === 'object' && newValue !== null) {
          oldValue = JSON.stringify(oldValue).replace(jsonRegex, "");
          newValue = JSON.stringify(newValue).replace(jsonRegex, "");
        }

        descriptionArray.push({
          field: key,
          oldValue: oldValue,
          newValue: newValue
        })
      }
      return descriptionArray
    },
    dateFormatter(row, col, value, index) {
      return moment.utc(value).format("DD/MM/YYYY HH:mm:ss");
    },
    isDate: function (dateStr) {
      const formats = [
        'YYYY-MM-DDTHH:mm:ss',
        'YYYY-MM-DDTHH:mm:ssZ'
      ];

      const momentDate = moment(dateStr, formats, true);
      return momentDate.isValid();
    },
    formatDate(value) {
      const momentDate = moment(value, moment.ISO_8601, true);
      if (momentDate.isValid()) {
        return momentDate.format("DD/MM/YYYY");
      } else {
        return value;
      }
    },
    strippedHtml(value) {
      if (typeof value === 'string' || value instanceof String) {
        let regexHtml = /(<([^>]+)>)/ig;
        return value.replace(regexHtml, " ");
      }

      return value;
    }
  },
};
</script>
