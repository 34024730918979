const SET_CUSTOMEVENTS = (state, customEvents) => {
    state.customEvents = customEvents
}

const SET_LOGS = (state, logs) => {
    state.logs = logs
}

const SET_CUSTOMEVENT_CURRENT = (state, customEvent) => {
    state.customEventCurrent = customEvent;
}

export default {
    SET_CUSTOMEVENTS,
    SET_LOGS,
    SET_CUSTOMEVENT_CURRENT,
}