import Vue from "vue";
import Bahngleis from "@/apis/bahngleis";
const bahngleis = new Bahngleis();

const getOrganizations = async ({ rootState, commit }, params) => {
  params = params || {};
  params = Object.assign(params, { ecommerceName: rootState.ecommerce.Name });
  return await bahngleis.get(`Organization/GetPaginated`, { params: params }).then(response => {
    commit('SET_ORGANIZATIONS', response.data.Items);
    return response;
  });
}

const getOrganizationsByEcommerce = async ({ rootState, commit }, params) => {
  params = params || {};
  params = Object.assign(params, { ecommerceName: rootState.ecommerce.Name });
  return await bahngleis.get(`Organization/GetByEcommerce`, { params: params }).then(response => {
    commit('SET_ORGANIZATIONS', response.data);
    return response;
  });
}

const removeOrganization = async ({ rootState }, id) => {
  return await bahngleis
    .delete(`/Organization/${id}`)
    .then(res => { return Promise.resolve(res) })
    .catch(err => { return Promise.reject(err.response.data) });
}

const getOrganizationLog = async ({ commit }, id) => {
  return await bahngleis
    .get(`/LogAction/getlogsofentity?identity=${id}&entity=Organization`)
    .then(response => {
      commit('SET_LOGS', response.data);
    });
}

const saveOrganization = async ({ rootState }, payload) => {
  payload = Object.assign(payload, { ecommerceName: rootState.ecommerce.Name });
  return await bahngleis
    .post(`Organization/`, payload);
}

const updateOrganization = async ({ rootState }, payload) => {
  return await bahngleis
    .put(`Organization/`, payload);
}

export default {
  getOrganizations,
  getOrganizationsByEcommerce,
  removeOrganization,
  saveOrganization,
  updateOrganization,
  getOrganizationLog,
}
